@import '../../../../variables';

$calendar-border-color: #bbb;

.LiveEventsCalendar {
  position: relative;
  margin-bottom: 20px;

  &__Card {
    overflow: clip;
  }

  &__StickyHeader {
    position: sticky;
    top: calc($app-header-height + 48px);
    z-index: 90;
    border-top-left-radius: 1px;
    border-top-right-radius: 1px;
  }

  &__PrimaryDateHeader {
    .rct-dateHeader {
      background-color: initial;

      & span {
        position: sticky;
        display: inline-block;
        left: 0px;
        right: 0px;
        padding: 0px 10px;
      }
    }
  }

  &__Timeline {
    .react-calendar-timeline .rct-header-root {
      background-color: white;
    }

    .react-calendar-timeline .rct-dateHeader {
      margin-left: -1px;
      border-left-width: 1px;
    }

    .react-calendar-timeline .rct-dateHeader-primary {
      color: #111;
    }

    .react-calendar-timeline .rct-calendar-header {
      border-top: 0;
      border-right: 0;
    }

    .rct-horizontal-lines div:last-child,
    .react-calendar-timeline .rct-sidebar .rct-sidebar-row:last-child {
      border-bottom: 0 !important;
    }

    .react-calendar-timeline .rct-sidebar .rct-sidebar-row {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      font-size: 12px;
      font-weight: bold;
      padding: 0 8px;
    }

    .react-calendar-timeline .rct-vertical-lines .rct-vl.rct-day-6,
    .react-calendar-timeline .rct-vertical-lines .rct-vl.rct-day-0 {
      background: rgba(0, 0, 0, 0.1);
    }

    .react-calendar-timeline .rct-sidebar-row,
    .react-calendar-timeline .rct-horizontal-lines > div {
      &.GameVersion,
      &.PerformanceEffect,
      &.TopIAP,
      &.AnalystReview,
      &.AverageRevenuePerDailyActiveUser {
        background-color: rgba(0, 0, 0, 0.15);
        font-size: 15px;
      }
    }

    .react-calendar-timeline .rct-sidebar-row,
    .react-calendar-timeline .rct-horizontal-lines > div {
      &.TrackingEvent--SpecialEvent,
      &.TrackingEventWithGame--SpecialEvent {
        font-size: 15px;
      }

      &:nth-last-child(1 of .TrackingEvent--SpecialEvent) {
        box-shadow: 0px 2px 3px rgba(0,0,0,0.3);
      }

      &:nth-last-child(1 of .TrackingEventWithGame--SpecialEvent) {
        box-shadow: 0px 3px 3px rgba(0,0,0,0.3);
      }
    }

    .react-calendar-timeline .rct-sidebar-row {
      &.group-even:not(.last-of-group),
      &.group-odd:not(.last-of-group) {
        border-bottom: none;
      }
    }

    .react-calendar-timeline .rct-sidebar-row,
    .react-calendar-timeline .rct-horizontal-lines > div {
      &.group-even {
        background: rgba(0, 0, 0, 0.05);
      }
    }

    .react-calendar-timeline .rct-sidebar-row,
    .react-calendar-timeline .rct-horizontal-lines > div {
      &.group-odd {
        background: white;
      }
    }

    .react-calendar-timeline .rct-sidebar-row,
    .react-calendar-timeline .rct-horizontal-lines > div {
      &.first-of-group {
        border-top: 1px solid $calendar-border-color;
      }
    }

    .react-calendar-timeline .rct-outer {
      transition: height 300ms;
    }

    &.calendarHidden .react-calendar-timeline .rct-outer {
      height: 0 !important;
      display: none;
    }
  }

  &__Info {
    color: $ultra-light-text-color;
  }

  &__ItemScreenshot {
    box-shadow: $card-box-shadow;
    border-radius: 8px;
  }

  .rct-item {
    &--SpecialEvent {
      height: 27px !important;
      margin-top: -3px;
    }
  }
}
