@import '../../../variables';

.MuiButton-root.ShowAdminButton {
  transition: all 250ms ease-out;
  .MuiButton-startIcon .MuiSvgIcon-root {
    transition: all 500ms ease;
  }
  &.active {
    background-color: $secondary-color;
    .MuiButton-startIcon .MuiSvgIcon-root {
      transform: rotate(-180deg)
    }
  }
}
