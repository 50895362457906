@import '../../variables';

.virtual-container {
  width: 100%;
  position: relative;
  overflow-y: hidden;
}

.table,
.table * {
  box-sizing: border-box;
}
