@import '../../../../variables';

.LiveEventsEventDialog {
  &__GameCardContent {
    line-height: 1.2;
  }

  .DescriptionLeftSticky {
    position: sticky;
    top: 0px;
  }

  .Screenshot {
    display: block;
    margin: 0 auto;
    max-width: 100%;
    max-height: 50vh !important;
    object-fit: contain;
    transition: all 200ms ease;
  }

  .Progress {
    left: 50%;
    margin: -20px 0 0 -20px;
    position: absolute;
    top: 50%;
  }

  .Thumbnail {
    box-shadow: $card-box-shadow;
    border-radius: 8px;
    max-width: 100%;
    cursor: pointer;
    transition: transform 0.2s ease-in-out;

    &:hover {
      transform: scale(1.05, 1.05);
    }
  }

  &__OtherGamesTab {
    &__Game {
      cursor: pointer;
      transition: scale 0.15s ease-in-out;

      &:hover {
        scale: 1.1;
      }
    }
  }

  .DescriptionTab {
    .ButtonGroup {
      height: 31px;

      button svg {
        font-size: 20px;
      }

      button.MuiButton-outlinedInfo {
        color: $light-color;
        border-color: $light-color;
      }
    }
  }
}
