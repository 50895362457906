@import 'src/variables';

.GameVisualsModal {
  .MuiDialog-paper {
    min-height: 80%;
  }
  .GameIcon {
    display: inline-block;
    vertical-align: top;
  }
  .MuiButton-root {
    color: $dark-text-color;
  }
}
