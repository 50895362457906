@import '../../../../variables';

$badge-size: 140px;

.LiveEventsNotPublishedBadge {
  position: absolute;
  left: 0px;
  top: 0px;
  width: $badge-size;
  height: $badge-size;
  overflow: hidden;
  pointer-events: none;

  &__Badge {
    position: absolute;
    height: 20px;
    width: $badge-size;
    top: 18px;
    left: -42px;
    background-color: $warning-color;
    color: white;
    text-align: center;
    padding: 5px 0px 2px;
    font-weight: bold;
    transform: rotate(-45deg);
    font-size: 9px;
    box-sizing: border-box;
    text-transform: uppercase;
  }
}
