@import '../../variables';

.DataGlossaryPage {
  z-index: 1;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;

  &__tabs {
    position: fixed;
    margin-top: -70px;
    z-index: 5;
    width: 75%;
    background-color: #fff;
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    box-shadow: $card-box-shadow;
    color: $secondary-color;
    overflow: hidden;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
  }

  &__Screenshot {
    box-shadow: $card-box-shadow;
    border-radius: 8px;
    display: block;
    margin: 0 auto;
  }

  &__ScreenshotText {
    color: $light-color;
    font-size: 14px;
  }
}
