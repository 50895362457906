@import '../../../../variables';

.SoftLaunchMarketList {
  vertical-align: middle;

  &__group {
    display: inline;
    vertical-align: middle;

    &__item {
      margin: 0 0.21em 0;
      padding-bottom: 0.21em;
      display: inline-block;
      vertical-align: middle;
      line-height: 1;

      &:first-child {
        padding-left: 0.42em;
        border-left: 1px solid rgba(0, 0, 0, 0.2);
      }

      &__small {
        font-size: 1rem;
      }

      &__medium {
        font-size: 24px;
      }

      &__large {
        height: 2rem;
      }
    }

    &:first-child &__item {
      padding-left: 0;
      border-left: none;
    }
  }
}
