@import '../../variables.scss';

.DateRangePicker {
  border-radius: $default-border-radius;
  border: 1px solid rgba(0, 0, 0, 0.23);

  input {
    width: 95px;
  }

  .active {
    color: $primary-color;
    border-radius: 3px;
    svg {
      color: $primary-color;
    }
  }

  .Mui-disabled {
    background-color: transparent;
  }
}
