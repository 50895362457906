@import '../../../../variables';

.selectableGameIconWitHTooltip {
  display: inline-block;
  margin: 0 12.5px 12.5px 0;
  vertical-align: top;
  width: 62px;
  p {
    display: block;
    font-size: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .GameIcon {
    border: 0 none;
    img {
      opacity: 0.65;
      border-radius: 22%;
    }
    &--selected img {
      opacity: 1;
    }
    &:hover {
      cursor: pointer;
      img {
        opacity: 1;
      }
    }
  }
  .loadingIcon {
    background-color: $app-background-color;
    border-radius: 22%;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.15);
    height: 62px;
    margin-bottom: 4px;
    text-align: center;
    width: 62px;
    .MuiCircularProgress-root {
      margin: 15px 0 0 1px;
    }
  }
  .loadingMask {
    color: transparent;
    background-color: $app-background-color;
  }
  .lockedIcon {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    transform: translate(60%, 60%);
    background-color: $secondary-color;
    color: white;
    padding: spacing(0.5);
    border-radius: 50%;
    cursor: pointer;
  }
}
