@import '../../variables';

.MarketSharePage {
  &__header {
    margin-top: -15px;
    position: sticky;
    top: $app-header-height;
    overflow: hidden;
    z-index: 3;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;

    &--mobile {
      margin-top: -15px;
      position: relative;
      top: auto;
    }
  }

  &__MarketShareLink {
    color: $dark-text-color;

    &:hover {
      cursor: pointer;
      color: $primary-color;
    }
  }

  &__Disabled {
    color: $subtle-color;
    cursor: not-allowed;
  }
}
