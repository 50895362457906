@import '../../variables';

.ProgressBar {
  width: 100%;
  .positive-impact {
    .MuiLinearProgress-bar2Buffer {
      background-color: $success-color;
      opacity: 0.8;
    }
  }

  .negative-impact {
    .MuiLinearProgress-bar2Buffer {
      background-color: $warning-color;
      opacity: 0.8;
    }
  }

  .MuiLinearProgress {
    &-dashed {
      display: none;
    }
  }
}
