.MyProfilePage {
  .input-with-bottom-margin {
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0px;
    }
  }

  hr.MuiDivider-root {
    margin-bottom: 20px;
  }
}
