.VisualIconsList {
  .MuiCardContent-root {
    margin: 0 -16px -24px 0;
  }
  .GameIcon {
    cursor: pointer;
    transition: all 200ms ease;
    transform: scale(1);
    &:hover {
      transform: scale(1.05);
    }
  }
  &__gameList {
    display: inline-block;
    margin: 0 12.5px 12.5px 0;
    vertical-align: top;
    width: 62px;
  }
}
