@import '../../../variables';

.root {
  line-height: inherit;

  &.grouping:not(:last-child) {
    border-right: 1px solid $table-column-group-border-color;
  }
  padding: 0.75em;
}
