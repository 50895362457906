.NewsListPreferencesDialog {
  .DialogContent {
    overflow-y: scroll;
  }

  &__actions {
    text-align: center;
  }

  &__news-entry-options {
    padding-left: 6px;
  }
}
