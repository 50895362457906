@import '../../variables';

.RankChange {
  font-size: 13px;
  color: $light-color;

  &__previous {
    display: inline-block;
    vertical-align: middle;
    padding: 0px 3px;
  }

  .MuiSvgIcon {
    &-root {
      display: inline-block;
      vertical-align: middle;
      margin: 0px -8px;
    }

    &-colorSuccess,
    &-colorWarning {
      margin: 0px -11px;
      font-size: 30px;
    }
  }
}
