@import '../../../../variables';

.GameSearchResult {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);

  &:hover {
    background-color: rgba(0, 0, 0, 0.05);

    .GameSearchResult__name {
      color: $primary-color;
    }
  }

  &:last-child {
    border-bottom: 0 none;
  }

  &__content {
    padding: 8px 8px 4px;
  }

  &__name {
    font-weight: bold;
    font-size: 14px;
    margin-top: 3px;
    color: $dark-text-color;
  }

  &__publisher {
    font-size: 13px;
    color: $light-text-color;
    margin-top: 2px;
  }
}
