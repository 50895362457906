@import '../../../../../variables.scss';

.DescriptionTab {
  @media screen and (max-width: $breakpoint-md) {
    flex-direction: column;
  }

  &__description {
    @media screen and (min-width: $breakpoint-md) {
      flex-basis: 50%;
      max-width: 50%;
    }

    &--fullscreen {
      flex-basis: 100%;
      max-width: 100%;
    }
  }

  &__fullscreenItemImage {
    flex-basis: 100%;
    max-width: 100%;
  }

  &__gridItemImage {
    @media screen and (min-width: $breakpoint-md) {
      flex-basis: 50%;
      max-width: 50%;

      &.animate-exit {
        flex-basis: 50%;
        max-width: 50%;
      }

      &.animate-exit-active {
        flex-basis: 100%;
        max-width: 100%;
        transition: all 300ms ease-in-out;
      }

      &.animate-exit-done {
        flex-basis: 100%;
        max-width: 100%;
      }

      &.animate-enter {
        flex-basis: 100%;
        max-width: 100%;
      }

      &.animate-enter-active {
        flex-basis: 50%;
        max-width: 50%;
        transition: all 300ms ease-in-out;
      }

      &.animate-enter-done {
        flex-basis: 50%;
        max-width: 50%;
      }
    }
  }
}
