@import '../../variables';

.Powerscore {
  position: relative;
  display: inline-block;

  &__circular-back {
    &.MuiCircularProgress-colorPrimary {
      position: absolute;
      color: #eee;
    }
  }

  &__value {
    position: absolute;
    width: 100%;
    top: 50%;
    transform: translateY(-50%);
    margin-top: -1px;
    text-align: center;
    font-weight: bold;
    color: $primary-color;
  }
}
