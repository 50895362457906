@import '../../../../variables';

.GameIconSelect {
  color: $light-color;
  border: 2px dashed $light-color;
  width: 100%;
  height: 0;
  padding-bottom: 100%;
  border-radius: $default-border-radius;
  box-sizing: border-box;
  background-color: rgba(0, 0, 0, 0.03);
  cursor: pointer;

  &:hover {
    border-color: $primary-color;
    color: $primary-color;
    transition: all 0.15s ease-in-out;
  }

  &__reviewIcon {
    width: 100%;
    border-radius: $default-border-radius;
  }
}
