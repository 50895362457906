@import '../../../../variables';
.FeatureSetRow {
  position: sticky;
  z-index: 3;
  top: 90px;
  transition: all 0.4s ease;
  &__stickyMode {
    background-color: white;
    box-shadow: $card-box-shadow;
    border-radius: 0 0px 20px 20px;
  }
}
