@import '../../../../variables';

.RichTextDialog {
  &__DialogContent {
    .public-DraftEditor-content {
      min-height: 50vh;
    }
    .rdw-editor-toolbar {
      margin-bottom: 0;
      border: 1px solid $divider-default-color;
      border-bottom: none;
      border-radius: 5px 5px 0 0;
      padding: 8px 8px 2px;
    }
    .rdw-editor-main {
      background-color: $white-color;
      border: 1px solid $divider-default-color;
      border-radius: 0 0 5px 5px;
    }
    .DraftEditor-root {
      padding: 0 8px;
    }
  }
}
