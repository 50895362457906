@import '../../variables';

.OrganizationUsersPage {
  .Card {
    margin-bottom: 10px;

    &__user {
      &:hover {
        box-shadow: 0px 0px 0px 2px $primary-color;
      }

      .MuiCardContent-root {
        padding-bottom: 16px;
      }

      h4 {
        margin-bottom: 5px;
      }
    }
  }
}
