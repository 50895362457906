@import '../../../../variables';

.root {
  .card-container {
    cursor: pointer;
    position: relative;

    &:hover {
      transition: box-shadow 0ms;
      box-shadow: 0 0 0 2px $primary-color;
    }
  }

  .card-content {
    padding: 8px 8px 4px !important;
  }

  .ribbon {
    position: absolute;
    transform: rotate(-45deg);
    top: spacing(2);
    left: spacing(-2);
    width: 80px;
    text-align: center;
    font-size: 10px;
    text-transform: uppercase;
    font-weight: bold;
    padding: 3px 0px 2px;
    background-color: $secondary-color;
    color: #fff;
    box-shadow: $card-box-shadow;
    z-index: 1;
  }
}

.game-card-content {
  .icon {
    position: relative;

    .badge {
      position: absolute;
      top: -6px;
      right: -6px;
      width: 20px;
      height: 20px;
      background-color: $warning-color;
      border-radius: $default-border-radius;
      cursor: help;
      border: 2px solid #fff;
      box-shadow: $card-box-shadow;
      text-align: center;

      .PriorityHigh {
        margin-top: 2px;
        color: #fff;
        font-size: 1rem;
        font-weight: bold;
      }
    }
  }

  .game-name {
    display: block;
    font-size: 1rem;
    font-weight: bold;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: auto;
  }

  .publisher {
    font-size: 0.75rem;
    color: $light-color;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: auto;
  }

  .subgenre {
    margin-top: spacing(1);
    .Chip {
      font-size: 0.6875rem;
      cursor: pointer;
      display: inline-grid;
      margin-right: spacing(0.5);
    }
  }

  .Powerscore {
    margin-top: 10px;
  }

  // table variant styles
  &.table {
    .game-name {
      font-size: 14px;
    }
  }
}

.action-button-popover {
  top: -5px;
}

.action-button-popover-content {
  min-width: 200px;
  box-sizing: border-box;
  padding: 15px 15px 5px;

  button {
    margin-bottom: 10px;
  }
}

.GameCard {
  &__blockIcon {
    color: rgba($color: $light-text-color, $alpha: 0.5);
    background-color: $app-background-color;
    border-radius: 50%;
    position: absolute;
    right: -6px;
    top: -4px;
    z-index: 1;
  }
}
