.SuggestAnalysisButton {
  display: inline-block;
  margin-top: -2px;

  &__popover {
    padding: 15px;
    max-width: 90%;
    width: 400px;
  }
}
