@import '../../../../variables';

.ArtistSearchInput {
  &__popper {
    z-index: 1000;
    color: $light-text-color;

    

    &__scroller {
      max-height: 250px;
      overflow-y: auto;
    
    }

    &__listItem {
      &:hover {
        cursor: pointer;
        background-color: $light-gray-background;
      }
    }
  }
}
