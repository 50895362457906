.LiveEventsTrackerPage {
    .tabidity {
        .MuiTab-root {
            border-top-left-radius: 20px;
            border-top-right-radius: 20px;
            padding-left: 20px;
            padding-right: 20px;
        }

        .Mui-selected {
            background-color: white;
            border-top: 1px solid #e0e0e0;
            border-right: 1px solid #e0e0e0;
            border-left: 1px solid #e0e0e0;            
            border-bottom: rgba(0,0,0,0);
        }

        .MuiTabs-indicator {
            background-color: rgba(0,0,0,0); 
          }
    }
}
