@import '../../../../variables';

.MarketExplorerGamesChartColorChip {
  background-color: rgba(0, 0, 0, 0.08);
  border-width: 2px;

  .MuiChip-labelMedium {
    display: inline-flex;
  }

  &:not(.selected) {
    border-color: transparent;
  }

  &.selected {
    .icon {
      color: $success-color;
    }
  }
}
