@import '../../../../variables';

.FeatureTabView {
  &__featureText {
    font-size: 14px;
    color: $light-color;
  }
  &__featureChoice {
    font-weight: bold;
    color: $light-text-color;
  }
  &__icon {
    border: 1px solid $primary-color;
  }
}
