.LiveEventsSecondaryTaxonomyTagList {
  display: flex;
  flex-wrap: wrap;
  margin: 0;

  .groupContainer {
    margin-bottom: 6px;
    margin-right: 4px;
    padding: 2px;
    border: 1px solid #eee;
    border-radius: 20px;
    background-color: lighten(#eee, 5%);
  }

  .groupName {
    font-size: 11px;
    margin-bottom: 0px;
    transform: translateX(8px);
  }

  .tagChip {
    margin-bottom: 0px;
    margin-right: 1px;

    span {
      font-size: 11px;
    }
  }
}
