@import 'src/variables';

.VisualsTableContainer {
  .GameIcon {
    display: inline-block;
    float: left;
    margin-right: 16px;
  }
  .MuiTableContainer-root {
    width: calc(100% - 116px);
    th {
      border-color: $subtle-color;
      text-transform: uppercase;
      svg {
        display: none;
      }
      h6 {
        cursor: pointer;
      }
    }
    td {
      border-color: $divider-default-color;
      line-height: 20px;
      padding: 0.1em 0;
      a {
        color: inherit;
        cursor: pointer;
        text-decoration: none;
      }
    }
    .value {
      display: inline-block;
      width: 50px;
    }
    .MuiTableCell-head {
      font-size: 12px;
      font-weight: bold;
      padding-top: .25em;
      padding-bottom: .5em;
    }
    .MuiLinearProgress {
      &-root {
        margin-left: -16px;
        width: 80px;
      }
    }
  }
}
