@import '../../variables';
$disabled-color: rgba(
  $color: $light-text-color,
  $alpha: 0.3,
);

.AddArea {
  display: flex;
  color: #888;
  font-size: 24px;
  border: 2px dashed #888;
  min-height: $card-min-height;
  border-radius: $default-border-radius;
  box-sizing: border-box;
  background-color: rgba(0, 0, 0, 0.03);
  cursor: pointer;

  &:hover {
    border-color: $primary-color;
    color: $primary-color;
    transition: all 0.15s ease-in-out;
  }

  .MuiSvgIcon-root {
    position: relative;
    top: 2px;
  }

  &__disabled {
    color: $disabled-color;
    border-color: $disabled-color;
    cursor: not-allowed;
    &:hover {
      color: $disabled-color;
      border-color: $disabled-color;
    }
  }
}
