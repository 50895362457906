@import '../../../../variables';

.GameSearchInput {
  &__input-wrapper {
    position: relative;
  }

  &__advanced-search {
    position: absolute;
    top: 1px;
    right: 2px;
  }

  &__popper {
    z-index: 5000;
    background-color: #fff;
    border-radius: $default-border-radius;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.4);
    overflow-x: hidden;
    overflow-y: auto;
    top: 3px !important;

    &__content {
      width: 300px;
      max-height: 70vh;
    }

    &__result-message {
      padding: 15px 0px;
      text-align: center;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      color: $light-text-color;
    }
  }
}
