@import '../../../../variables';

.GameAnalysisOutdatedIndicator {
  &__popoverContainer {
    align-self: center;
    margin-left: spacing(2);
  }

  &__popover {
    background-color: $white-color;
    border: 1px solid $primary-color;
    border-radius: 4px;
    padding: spacing(1) spacing(2);
    max-width: 300px;
  }
}
