@import '../../variables';

.image {
  box-shadow: $card-box-shadow;
  border-radius: 6px;
  display: block;
  margin: 0 auto;
  max-width: 100%;
  max-height: 75vh;
  object-fit: contain;
  transition: all 200ms ease;
}
