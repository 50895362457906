// GalleryCarousel.scss
@import '../../variables';


.GalleryCarousel {
    .DownloadAllButton {
      height: 30px;
      width: 30px;
      border: 1px solid $secondary-color;
    }

    .ButtonGroup {
        height: 31px;
  
        button svg {
          font-size: 20px;
        }
  
        button.MuiButton-outlinedInfo {
          color: $light-color;
          border-color: $light-color;
        }
      }

    .Progress {
        left: 50%;
        margin: -20px 0 0 -20px;
        position: absolute;
        top: 50%;
      }

    .Screenshot {
        cursor: pointer;
        overflow: hidden;
        display: block;
        margin: 0 auto;
        max-width: 100%;
        max-height: 50vh;
        object-fit: contain;
        transition: all 200ms ease;

        &--no-hover{
            cursor: default;
        }
    }

    .Thumbnail {
        box-shadow: $card-box-shadow;
        border-radius: 8px;
        max-width: 100%;
        cursor: pointer;
        transition: transform 0.2s ease-in-out;
    
        &:hover {
          transform: scale(1.05, 1.05);
    
        }

        &--no-hover{
          cursor: default;
        }        
      }
}
