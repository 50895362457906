@import '../../variables';

.GRTabNavigation {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  transition: border-radius 0.15s ease-in-out;

  &--sticky {
    position: sticky;
    top: $app-header-height;
    overflow: hidden;
    z-index: 100;
    margin-top: 0;
  }

  &--mobile {
    margin-top: 0;
    position: relative;
    top: auto;
  }

  &--pinned {
    border-bottom-left-radius: 1px;
    border-bottom-right-radius: 1px;
  }
}
