.NewsCardContentEditorialSection {
  @import '../../../../../../variables';

  &__editorial-content {
    margin-bottom: 20px;

    p {
      color: $light-text-color;
    }
  }
}
