@import '../../../../variables';

.GameStage {
  &__subtitle {
    color: $light-color;
    margin: 0;
  }
  &__title {
    text-transform: capitalize;
    margin: 0;
  }

  &__card-actions {
    padding: 0;
  }
}
