@import '../../variables';

.MultiSlider {
  .slider {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    overflow: hidden;
    position: relative;
    .viewport {
      width: 100%;
      overflow: hidden;
      .items {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        position: relative;
        margin: 0 auto;
        .item {
          margin: 0;
        }
        img {
          max-width: 100%;
          max-height: 100%;
        }
      }
    }
    .naviButton {
      height: 40px;
      background-color: white;
      &.next,
      &.prev {
        margin: auto 10px;
      }
      &:hover {
        color: white;
        background-color: #bd65cf;
      }
      &.floating {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        &.prev {
          left: 0;
          z-index: 1;
        }
        &.next {
          right: 0;
        }
      }
      &.disabled {
        cursor: not-allowed;
        color: $subtle-color;
        background-color: $light-gray-background;
      }
    }
  }
  .footer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
}
