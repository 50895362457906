@import '../../../../variables';

.GameKeyIndicators {
  &__item {
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0px;
    }

    &__title {
      font-weight: bold;
      font-size: 12px;
      padding-bottom: 5px;
      color: $light-text-color !important;
    }

    &__container {
      padding: 5px 0px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      font-size: 14px;

      &:last-child {
        border-bottom: none;
      }
    }
  }
}
