@import '../../variables';

.AppHeader {
  z-index: 100;

  &--sticky {
    position: sticky;
    top: 0px;
  }

  #app-header {
    height: 70px;
    background-color: $app-background-color;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    display: flex;
    align-items: center;
    box-shadow: 0 4px 5px -5px rgba(0, 0, 0, 0.1);
    padding: 10px 0px 0px 60px;
  }

  &--sticky {
    #app-header {
      height: $app-header-height;
      padding: 15px 0px 0px 10px;
    }
  }

  #app-page-name {
    flex-basis: 50%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 24px;
    font-weight: bold;
    color: $secondary-color;
    align-items: center;
    text-transform: capitalize;
  }
}
