.FtueVideoPreview {
  .VimeoPreview {
    background-color: transparent;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    margin: 0 auto;
    position: relative;
  }
  .MuiButton {
    &-root {
      border-radius: 48px;
      top: 50%;
      left: 50%;
      margin-left: -24px;
      margin-top: -24px;
      min-width: 0;
      position: absolute;
      padding: 4px;
      svg {
        height: 48px;
        width: 48px;
        margin: 0;
      }
    }
    &-startIcon {
      margin: 0;
    }
  }
}
