@import '../../../../variables';

.container {
  overflow: auto;
  width: 100%;
}

.rank-value {
  font-size: 18px;
  font-weight: bold;

  &--outside {
    color: $light-color;
  }
}
