@import '../../../../variables';

.EffectImpactDiffText {
  float: right;
  display: flex;
  .positive {
    color: $success-color;
  }
  .negative {
    color: $warning-color;
  }
}
