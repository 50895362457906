@import '../../../../variables';

.NewsCard {
  margin-bottom: 15px;
  position: relative;

  .Card {
    position: relative;
    border-radius: $default-border-radius;
    overflow: hidden;
  }
}
