@import '../../../../variables';

.FtueVideoStaticPreviewCard {
  &__lazyImage {
    width: 100%;
  }
  &__cardContainer {
    cursor: pointer;
    &:hover {
      border: 1px solid $primary-color;
    }
  }
  h3 {
    font-size: 18px;
    font-weight: bold;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  p.artist {
    color: $light-color;
    font-size: 12px;
    margin-bottom: 0.1em;
  }
  p.version {
    font-size: 12px;
  }
}
