@import '../../../variables';

.ChatBubble {
  @keyframes pulse {
    0% {
      background-color: $primary-color;
      transform: rotate(0deg);
    }
    50% {
      background-color: $secondary-color;
      transform: rotate(180deg);
    }
    100% {
      background-color: $primary-color;
      transform: rotate(360deg);
    }
  }

  .pulse-avatar {
    animation: pulse 4s infinite;
    animation-delay: 1s;
    transition: all 0.5s ease-in-out;
  }

  .error {
    background-color: $warning-color;
  }

  .link-item {
    padding-left: 24px;
  }

  .MuiTypography-root p {
    margin-block-start: 0px !important;
  }
}
