.ShowMore__root {
    position: relative;
    &:not(.ShowMore__expanded) {
        &::after {
            position: absolute;
            display: inline-block;
            z-index: 1;
            top: 60%;
            left: 0px;
            pointer-events: none;
            background-image: linear-gradient(to bottom, 
                    rgba(255,255,255, 0), 
                    rgba(255,255,255, 1) 90%);
            width: 100%;
            height: 40%;
            content: "";
        }
    }
}