@import '../../../../variables';

.ImplementationExamples {
  .color-primary {
    color: $primary-color;
  }

  .selected {
    box-shadow: 0 0 0 2px $primary-color;
  }
}
