.LiveEventsSecondaryTaxonomySelector {
  .tagGroup {
    span {
      font-weight: bold;
      font-size: 14px;
    }
  }

  .tag {
    span {
      font-size: 12px;
    }
    .MuiCheckbox-root {
      padding-top: 6px;
      padding-bottom: 6px;
    }
  }

  .groupCard {
    .MuiCardContent-root {
      padding-top: 5px;
      padding-bottom: 16px;
    }
    .header {
      padding-bottom: 5px;
    }
    .content {
      padding-bottom: 8px;
    }
  }
}
