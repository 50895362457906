@import '../../../../variables.scss';

.MotivationTypeBadge {
  display: inline-block;

  &--Selectable {
    .MotivationTypeBadge__Content {
      cursor: pointer;
    }
  }

  &--AlignLeft {
    .MotivationTypeBadge__Content {
      margin-left: 0px;
      margin-right: 16px;
    }
  }
  
  &__Content {
    padding: 0px 8px 0px 4px;
    box-shadow: $card-box-shadow;
    border-radius: $default-border-radius;
    font-size: 11px;
    font-weight: bold;
    margin: 0px 8px 12px 8px;
    transition: transform 0.15s ease-in-out;
    background-color: white;
    border: 2px solid transparent;
    box-sizing: border-box;

    &--Selected {
      border: 2px solid $primary-color;
      box-shadow: $card-box-shadow-large;
    }
    
    &:hover {
      transform: scale(1.1, 1.1);
    }
  }

  &__Type {
    svg {
      width: 18px;
      margin-top: 2px;
      margin-left: 2px;
      margin-right: 5px;
    }
  }

  &__Level {
    background-color: $segment-color-top-game;
    text-align: center;
    color: white;
    height: 20px;
    width: 20px;
    border-radius: 20px;
    margin-right: 6px;

    svg {
      font-size: 19px;
    }
  }

  &__Active {
    background-color: white;
    text-align: center;
    color: $primary-color;
    height: 20px;
    width: 20px;
    border-radius: 20px;   
    margin-left: 5px; 

    svg {
      font-size: 19px;
    }
  }

  &:not(.MotivationTypeBadge--Active) {
    .MotivationTypeBadge__Content {
        padding-right: 10px;
    }  
  }
}