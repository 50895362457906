.MarketTrendsPage {
  .TagList {
    button.MuiToggleButton-root {
      font-size: 13.75px;
      padding-left: 1em;
      padding-right: 1em;
    }
  }
  .DateRangePicker, .SelectDropdown {
    margin-left: .5em;
    vertical-align: middle;
  }
  .SelectDropdown * {
    text-transform: capitalize;
  }
  .GRCountryFlag {
    font-size: 22px;
    margin-top: -3px;
    vertical-align: bottom;
  }
}
