@import '../../../../variables';
.FeatureCard {
  .Mui-selected {
    background-color: $secondary-color;
    color: white;
    &:hover {
      background-color: $secondary-color-light;
    }
  }

  .MuiGrid-container {
    align-items: center;
  }

  &__EditButton {
    border: 1px solid $primary-color;
  }

  .HasNote {
    background-color: $primary-color;
    color: $white-color;
  }
}

.ScoreBar {
  &.disabled {
    opacity: 0.5;
  }

  &__text {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    font-size: 0.9rem;
  }
  &__progressBarTooltip {
    width: 100%;
  }
}

.FeatureSelect {
  width: 100%;

  &.hasValue {
    .MuiOutlinedInput-root {
      background-color: $secondary-color;
      color: white;
      .MuiSvgIcon-root {
        color: white;
      }
    }
  }
}
