@import '../../../../../variables';

.NewsCardSubHeader {
  &__date {
    color: $light-color;
    margin-bottom: 5px;
    font-size: 14px;
  }

  &__title {
    margin-bottom: 10px;
  }

  &__subtitle {
    font-size: 16px;
    color: $light-color;
    margin-top: -5px;
    margin-bottom: 10px;
  }

  &__powerscore {
    padding: 0px 5px 0px 20px;
    margin-left: 20px;

    &--divider {
      border-left: 1px solid rgba(0, 0, 0, 0.1);
    }

    &__title {
      font-size: 12px;
      font-weight: bold;
      margin-bottom: 10px;
    }
  }

  &__ftue-video {
    margin-left: 15px;

    &__title {
      font-size: 12px;
      font-weight: bold;
      margin-bottom: 5px;
      text-transform: uppercase;
    }

    &__action {
      cursor: pointer;

      &__icon {
        font-size: 60px !important;
      }
    }
  }
}
