@import 'src/variables';

.ScreenshotModal {
  z-index: 1301;

  .MuiDialog-paper {
    min-height: 95%;
  }

  .header {
    .closeButton {
      margin: -8px -4px 8px 0;
    }
    button {
      margin-left: 0.5em;
      svg {
        transition: transform 0.275s ease-out;
        &.upsideDown {
          transform: rotate(180deg);
        }
      }
    }
    .GameIcon {
      vertical-align: top;
    }
    .gameContent {
      color: $dark-text-color;
      display: inline-block;
      margin-left: 16px;
    }
    .gameName {
      font-size: 1.05em;
      color: $primary-color;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      max-width: 300px;
    }
    .artist {
      color: $light-color;
      font-size: 0.75em;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
    .version {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }
  .releaseNotes {
    border-top: 1px solid $divider-default-color;
    overflow: auto;
    padding: 8px 16px;
    svg {
      display: inline-block;
      margin-right: 16px;
      width: 72px;
    }
    > div {
      display: inline-block;
      vertical-align: middle;
      width: calc(100% - 116px);
    }
  }

  .images {
    img {
      box-shadow: $card-box-shadow;
      border-radius: 6px;
      display: block;
      margin: 0 auto;
      max-width: 100%;
      max-height: 75vh;
      object-fit: contain;
      transition: all 200ms ease;

      &.loadingImage {
        filter: blur(3px);
        opacity: 0.85;
      }
    }

    .MuiCircularProgress-root {
      left: 50%;
      margin: -20px 0 0 -20px;
      position: absolute;
      top: 50%;
    }
  }
  .modalFooter {
    .inline {
      display: inline-block;
      vertical-align: middle;
    }
    img.analystImage {
      border-radius: 50%;
      display: inline-block;
      margin-left: 16px;
      max-width: 72px;
      max-height: 72px;
      overflow: hidden;
      vertical-align: middle;
    }
    p {
      margin: 0.15em 0 0;
    }
    q p {
      display: inline;
      margin: 0;
    }
  }
  .featuresFooter {
    display: block;
    padding-top: 0;
    .MuiDivider-root {
      margin: 0 0 4px;
      p {
        color: $light-color;
      }
    }
  }
  .footer {
    margin-top: 20px;
    background-color: white;
    padding: 20px;
    border-top: 1px solid $divider-default-color;
  }
}
