@import '../../../../variables';

.FeatureChoice {
  font-weight: bold;

  &__text {
    color: $success-color;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__no {
    color: $subtle-color;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__yes {
    color: $success-color;
  }
}
