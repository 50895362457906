@import '../../../../variables';

.MarketShareTopGameCard {
  .MuiPaper-root {
    border-radius: 12px;
    padding: 3px;
  }

  .GameIcon {
    display: block;
  }

  &__Data {
    padding: 4px 0px 2px;
    text-align: left;
    font-size: 11px !important;
  }

  &__DominantGameIndicator {
    position: absolute;
    width: 23px;
    height: 23px;
    top: -3px;
    right: -6px;
    border-radius: 50%;
    box-shadow: $card-box-shadow;
    background-color: $primary-color;
    cursor: help;
    color: white;
    text-align: center;
    border: 2px solid white;
    box-sizing: border-box;
    padding-top: 2px;
  }
}
