@import '../../../../variables';
$icon-bg: rgba($light-color, 0.3);

.LimitedCardHeader {
  &__icon {
    width: 57px;
    height: 57px;
    background-color: $icon-bg;
    border-radius: 10px;
    box-shadow: $card-box-shadow;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $ultra-light-text-color;
  }
}
