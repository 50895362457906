@import 'src/variables';

.chapterList {
  max-height: 100vh;
  padding-bottom: 30px;
  h6 {
    color: $light-color;
    font-size: 16px;
  }
  .GameIcon--normal {
    margin-right: 12px;
    vertical-align: top;
  }
  .gameDetails {
    display: inline-block;
  }
  .videoChapter {
    padding: 8px;
    padding-bottom: 6px;
    border-top: 1px solid $divider-default-color;
    border-right: 3px solid transparent;
    transition: background-color 750ms ease-out, border-color 750ms ease-out;
    & > * {
      display: inline-block;
      margin-right: 12px;
      vertical-align: middle;
    }
    .time {
      color: $light-text-color;
      align-self: center;
    }
    .tag {
      margin-right: 6px;
      transition: background-color 1s ease-out;
      margin-bottom: 6px;
    }
    .selected {
      background-color: $primary-color;
    }
    .highlight {
      border: 2px solid $primary-color;
    }
  }
  .videoChapterHighlight {
    background-color: $light-gray-background;
  }
}
