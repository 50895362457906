@import '../../../../variables';

.root {
  color: $dark-text-color;
  padding-left: 1em;

  &.active,
  &.active * {
    color: $primary-color;
  }
}
