.gameSearchWithImplementationsDialog {
  .MuiDialogContent-root .MuiTypography-h4 {
    font-size: inherit;
    font-weight: bold;
  }

  &__Content {
    min-height: 700px;
  }
}
