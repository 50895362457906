@import '../../../../variables';

.ftueContainer {
  position: relative;
  .remove {
    background-color: $primary-color;
    color: white;
    position: absolute;
    right: -8px;
    top: -8px;
    &:hover {
      background-color: lighten($primary-color, 10%);
    }
  }
}

.ftueVideoPlayer {
  .ftueMedia {
    background-color: $secondary-color;
    overflow: hidden;
    padding: 0;
    position: relative;
    .video {
      min-height: 360px;
      width: 100%;
    }
    .volumeControl {
      background-color: white;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
      bottom: 0;
      height: 160px;
      padding: 8px 8px 16px 8px;
      position: absolute;
      right: 28px;
      text-align: center;
      p {
        color: $light-color;
        font-size: 12px;
        line-height: 12px;
        margin: 4px 0 16px;
      }
    }
  }
  .videoControls {
    padding: 0;
    .Mui-disabled {
      background-color: transparent;
    }
    .MuiSlider {
      &-rail {
        background-color: $app-background-color;
        opacity: 1;
      }
      &-thumb {
        border: 4px solid $primary-color;
        background-color: white;
        height: 20px;
        width: 20px;
        &.Mui-disabled {
          border-color: $subtle-color;
        }
      }
      &-track {
        color: lighten($primary-color, 20%);
      }
    }
    svg {
      display: inline;
    }
    .playButton svg {
      height: 36px;
      width: 36px;
    }
    .time {
      color: $light-text-color;
      display: inline-block;
      margin-right: 4px;
      text-align: left;
      vertical-align: middle;
      width: 50%;
    }
  }
}
