@import '../../variables';

.GameSubgenrePicker {
  position: relative;
  font-size: 16px;
  margin-bottom: spacing(2);
  z-index: 2;

  &__header {
    margin-bottom: 10px;
  }

  .category {
    font-weight: bold;
    box-sizing: border-box;

    &__name {
      padding: 10px 10px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    }
    &__subCategoryContainer {
      &__collapsed {
        @media (max-width: $breakpoint-lg) {
          width: 49.5%;
        }

        @media (max-width: $breakpoint-sm) {
          width: 100%;
        }

        width: 24.5%;
        position: absolute;
      }
    }

    &:hover {
      .category__name {
        color: $primary-color;
      }
    }
  }

  .genre {
    font-weight: bold;
    font-size: 14px;

    &__name {
      padding: spacing(1.2) spacing(1) spacing(1.2) 0;
      margin-left: 20px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      color: $dark-text-color;
    }

    &__subgenres {
      margin-left: 40px;
    }

    &:hover {
      .genre__name {
        color: $primary-color;
      }
    }
  }

  .subgenre {
    font-weight: normal;
    padding: 8px 10px 6px 0px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    font-size: 13px;

    &__selected-icon {
      position: relative;
      height: 15px;
      width: 25px;
      top: -6px;
      box-sizing: border-box;
    }

    &:last-child {
      padding-bottom: 10px;
      border-bottom: 0;
    }
  }

  .picker-element {
    cursor: pointer;

    &:hover {
      color: $primary-color;
    }

    &--selected {
      color: $success-color !important;
      font-weight: bold;
    }

    &--disabled {
      color: $subtle-color !important;
      cursor: not-allowed;
    }
  }

  &__picker {
    margin-bottom: spacing(1);

    &--collapsed {
      position: relative;
      z-index: 1;

      .category {
        &__name {
          border-bottom: 1px solid transparent;
        }

        &:hover {
          .category__name {
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);
          }

          .genre {
            display: block;
            pointer-events: all;
          }
        }
      }

      .genre {
        &:last-child {
          .genre__name {
            border-bottom: 0 none;
            padding-bottom: 15px;
          }
        }

        &:hover {
          position: relative;

          .genre__subgenres {
            position: absolute;
            width: 70%;
            top: 25px;
            right: -15px;
            padding: 3px 10px 0px;
            z-index: 2;
            display: block;
            pointer-events: all;
            background-color: #fff;
            border-radius: 4px;
            box-shadow: $card-box-shadow-large;
          }
        }
      }

      .genre,
      .genre__subgenres {
        display: none;
        pointer-events: none;
      }
    }

    .Chip {
      margin: 0;
    }
  }

  &__selected-subgenres {
    padding-top: 5px;
  }
}
