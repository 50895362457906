@import '../../../../variables';

.GameOverview {
  &__grid-container {
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0px;
    }
  }

  .Card {
    &--full-height {
      height: 100%;
    }
  }

  .AppStoreInfoRow {
    margin-bottom: 15px;
    color: $light-text-color !important;
  }
}
