.MarketExplorerPage {
  &__header {
    position: relative;
    margin-top: 5px;
    overflow: hidden;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }

  &__header-sticky {
    position: sticky;
    top: 89px;
    overflow: hidden;
    z-index: 5;
    margin-bottom: 20px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    margin-top: 0px;
    border-top: 1px solid rgba(0, 0, 0, 0.1);

    &--mobile {
      position: relative;
      top: auto;
    }
  }
}
