@import '../../variables';

.item {
  text-transform: capitalize;
  color: #fff;

  .icon {
    color: #fff;
    opacity: 0.5;
    transition: opacity 0.1s ease-in-out;
  }

  &.selectable.selected,
  &:not(.selectable),
  &.selectable:not(.selected):hover {
    &._5c754ed27d198e5f16899e7a {
      // Seasonal
      background-color: lighten($secondary-color, 20%);
    }

    &._5c754e947d198e5f16899e78 {
      // Feature
      background-color: $primary-color;
    }

    &._5c754efb7d198e5f16899e7c {
      // Content
      background-color: #444;
    }

    &._5c754ebf7d198e5f16899e79 {
      // Event
      background-color: $success-color;
    }

    &._5c754ee67d198e5f16899e7b {
      // Fixes
      background-color: $warning-color;
    }

    .icon {
      opacity: 1;
    }
  }

  &.selectable:not(.selected):hover .icon {
    opacity: 0.25;
  }

  &.selectable:not(.selected) {
    background-color: $subtle-color;
  }

  &.condensed {
    margin: 0 0.25rem 0.25rem 0;
    &:last-child {
      margin-right: 0;
    }
  }
}
