@import 'src/variables';

.VisualExplorerPage {
  position: relative;
  .MuiButton-root {
    transition: all 350ms ease-in;
    opacity: 1;
    transform: scale(1.0);
    &.hidden {
      opacity: 0;
      transform: scale(0.1);
    }
  }
  &__header {
    position: sticky;
    top: -452px;
    overflow: hidden;
    z-index: 1;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
  }
  .MuiTabs-root a {
    font-size: 12px;
    font-weight: bold;
  }
  .selectedParams .MuiButton-root {
    color: #777;
    border-color: #777;
  }
  .capitalize {
    text-transform: capitalize;
  }
  .selectableAttributes {
    > .MuiBox-root {
      border-bottom: 1px solid $divider-default-color;
    }
    .clear {
      .MuiButton-startIcon {
        margin-right: 0.1em;
      }
    }
    .MuiButton-root {
      text-transform: capitalize;
    }
    .dropdown {
      min-width: 225px;
      text-align: left;
      p {
        width: 100%;
        &:hover {
          color: $primary-color;
        }
      }
      .MuiChip {
        &-root {
          margin: 0;
        }
        &-label {
          line-height: 1em;
        }
      }
      svg {
        transition: transform 250ms;
      }
      &.open svg {
        transform: rotate(180deg);
      }
    }
    .MuiCollapse-root {
      .MuiButton-root {
        margin: 0 .35em .5em 0;
        .MuiChip-root {
          background-color: white;
          display: none;
          font-size: 11px;
          margin: 0 -4px 0 8px;
        }
        &.selected {
          .MuiChip-root {
            display: inline-block;
          }
        }
      }
    }
  }
  .MuiChip-root.numbersChip {
    border: 1px solid $divider-default-color;
    background-color: transparent;
  }
}
