@import '../../../variables';

.AddBookmarkButton {
  button {
    box-shadow: none;
    width: 32px;
    height: 32px;
    transform: translate(-2px, 2px);
  }

  &--AddNewBookmark {
    button {
      background-color: #ddd;
      color: $secondary-color;
    }
    :hover {
      button {
        color: white;
      }
    }
  }
}
