@import '../../variables';

$font-size-small: 1rem;
$font-size-medium: 24px;
$font-size-large: 2rem;

.root {
  height: auto !important;
  box-shadow: $card-box-shadow;
  border-radius: 2px;
  box-sizing: border-box;
}

.small {
  font-size: $font-size-small;
}

.medium {
  font-size: $font-size-medium;
}

.large {
  font-size: $font-size-large;
}
