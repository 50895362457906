@import '../../../../../../variables';

.NewsCardContentReleaseNotesSection {
  pre {
    font-size: 14px;
    line-height: 20px;
    color: $light-text-color;
  }

  &__version {
    font-weight: bold;
    color: $light-text-color;
    margin-bottom: 10px;
  }
}
