@import '../../../../../../variables';

.NewsCardContentRevenueAndDownloadsSection {
  h6 {
    font-size: 14px;
    font-weight: 900;
  }

  &__header {
    text-align: center;
    font-size: 14px;
    font-weight: 700;
  }

  &__chart {
    height: 500px;
  }

  &__rev-dl-description {
    color: $light-color;
  }
}
