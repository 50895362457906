@import '../../../../variables';

.container {
  overflow: auto;
  width: 100%;
}

.powerScoreContainer {
  margin-right: spacing(2);
  border-color: $secondary-color;

  .ribbon {
    font-size: 11px;
    border-color: $secondary-color;
    color: $secondary-color;
    width: 70px;
    text-transform: uppercase;
    margin-bottom: spacing(1);
    margin-right: 0;
    height: 18px;
  }
}
