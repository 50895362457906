@import '../../variables';

.GamePage {
  &__content {
    position: relative;
  }

  &__navigation {
    .MuiTabs-scrollButtons.Mui-disabled {
      opacity: 0.3;
    }

    a.MuiTab-root {
      font-size: 12px;
      font-weight: bold;
      padding-left: 12px;
      padding-right: 12px;
    }
  }
}
