.GameSearchDialog {
  .MuiDialogContent-root {
    padding: 0px 24px;
  }

  &__tabs {
    margin-bottom: 10px;
  }

  &__content {
    padding: 16px 0px;
    min-height: 700px;
  }
}
