@import '../../variables';


.LockedFeatureIndicator {
  max-width: 90%;
  &__features {
    text-overflow: ellipsis;
    overflow: hidden; 
    white-space: nowrap;
    max-width: 100%;
    
    @media (max-width: $breakpoint-lg) {
      max-width: 180px;
    }
  }
}
