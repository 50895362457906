@import '../../../../variables';

.root {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 5px;

  &.FeatureChanges {
    background-color: $primary-color;
  }

  &.SystemUpdate {
    background-color: $secondary-color;
  }

  &.ScreenshotsAvailable {
    background-color: $success-color;
  }
}
