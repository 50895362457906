@import '../../../../variables';

.LiveEventFeedItemCard {
  &__CardComponent  {
    overflow: visible;
  }

  &__NewItemHeader {
    padding: 10px;
    background-color: $primary-color;
    color: white;
    text-align: center;
    border-top-left-radius: $default-border-radius;
    border-top-right-radius: $default-border-radius;
  }

  &__ItemScreenshot {
    box-shadow: $card-box-shadow;
    border-radius: 8px;
    max-width: 100%;
    max-height: 500px;
    cursor: pointer;
    transition: all 0.15s ease-in-out;
    position: sticky;
    margin-top: 5px;
    top: 160px;

    &:hover {
      transform: scale(1.02, 1.02);
    }
  }

  &__HTMLContent {
    p:first-child {
      margin-top: 0px;
    }
  }
}
