@import '../../variables';

$masked-bg: rgba($light-color, 0.3);

.MaskedText {
  &__box {
    background-color: $masked-bg;
    width: spacing(1);
    display: inline-block;
  }
}
