.AIAnalystPage {
  &__root {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 0px 17px;
  }

  &__input {
    height: 110px;

    .MuiOutlinedInput-root {
      border-radius: 50px !important;
    }
  }

  &__progress {
    scale: 0.5;
  }

  &__message-container {
    min-height: calc(100vh - 110px);
    padding-bottom: 50px;
  }

  &__filler-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    min-height: calc(100vh - 110px);
  }

  &__input-container {
    height: 90px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    overflow: hidden;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 1px;
  }

  &__footer {
    position: sticky;
    background-color: #eaeefd;
    box-sizing: border-box;
    z-index: 100;
    bottom: 0px;
    overflow: hidden;
    padding-bottom: 20px;
    margin: 0px -27px -15px;
  }
}
