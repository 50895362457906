@import '../../variables';

.SingleUnifiedNewsEntryPage {
  .NewsCard {
    @media (min-width: $breakpoint-md) {
      padding-right: 16px;
      border-right: 1px solid rgba(0, 0, 0, 0.1);
    }
  }
}
