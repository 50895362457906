@import '../../../variables';

.collectionAdmin {
  background-color: white;
  transition: all 300ms linear;
  .MuiCardContent-root {
    border-top: 1px solid $divider-default-color;
  }
  .MuiGrid-item {
    padding: 4px;
  }
  .collectionAdminHeader {
    border-top: 0 none;
    button {
      margin-left: 8px;
    }
  }
  .selectionLabel {
    padding-right: 1em;
    text-align: right;
  }
  .peopleFlow {
    .MuiInputBase-multiline {
      padding: 8px;
    }
    option {
      padding: .25em;
    }
    .MuiButton-root {
      margin-top: 12px;
    }
  }
}
