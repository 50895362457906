@import '../../variables';

$check-icon-url: '../../assets/images/check-icon.svg';

.LockedFeatureContent {
  @media (max-width: $breakpoint-md) {
    flex-direction: column;
  }
  
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    padding-left: 25px;
  }

  li {
    margin-bottom: 1rem;
    padding-left: 15px;
    position: relative;

    &:before {
      display: block;
      content: ' ';
      background-color: $success-color;
      -webkit-mask-image: url($check-icon-url);
      mask-image: url($check-icon-url);
      background-size: 24px 24px;
      height: 24px;
      width: 24px;
      position: absolute;
      top: -3px;
      left: -16px;
    }
  }

  &__Image {
    max-width: 100%;
    border-radius: 14px;
    box-shadow: $card-box-shadow;
  }

  &__container {
    @media (max-width: $breakpoint-md) {
      flex-direction: column;
    }
  }
}
