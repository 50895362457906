@import '../../../../../../variables';

.EventScreenshotGallery {
    .ScreenshotContainer{
        position: relative;
        .ScreenshotGalleryDeleteBtn {
            padding-left: 6px;
            padding-right: 6px; 
            min-width: 0;
            opacity: 0;
            transition: opacity 300ms;
            position: absolute;
            right: 8px;
            top: 8px;
        }
        &:hover {
            .ScreenshotGalleryDeleteBtn {
                transition: opacity 300ms;
                opacity: 1;
                cursor: pointer;
            }
        }
    }
}