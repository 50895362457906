@import '../../../../variables';

.root {
  margin-bottom: 15px;

  .card-container {
    cursor: pointer;
    position: relative;

    &:hover {
      box-shadow: 0px 0px 0px 2px $primary-color;
    }
  }

  .card-content {
    padding: 8px 8px 4px !important;
  }
}

.game-card-content {
  .icon {
    position: relative;

    .badge {
      position: absolute;
      top: -6px;
      right: -6px;
      width: 20px;
      height: 20px;
      background-color: $warning-color;
      border-radius: $default-border-radius;
      cursor: help;
      border: 2px solid #fff;
      box-shadow: $card-box-shadow;
      text-align: center;

      .PriorityHigh {
        margin-top: 2px;
        color: #fff;
        font-size: 1rem;
        font-weight: bold;
      }
    }
  }

  .game-name {
    display: block;
    font-size: 1rem;
    font-weight: bold;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: auto;
  }

  .publisher {
    font-size: 0.75rem;
    color: $light-color;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: auto;
  }

  .subgenre {
    .Chip {
      font-size: 0.6875rem;
      cursor: pointer;
    }
  }

  .Powerscore {
    margin-top: 10px;
  }

  // table variant styles
  &.table {
    .game-name {
      font-size: 14px;
    }
  }
}
