@import '../../../../variables';

.CategoryImage {
  img {
    width: 1.5rem;
    vertical-align: middle;
    filter: $primary-image-filter-color;
  }

  &.small {
    img {
      width: 1rem;
    }
  }
}
