.ArchetypeDescriptions {
  &__archetypeTabs {
    border-bottom: 1px solid #e0e0e0;
    width: 100%;
  }

  &__archetypeTabsContainer {
    padding: 0;
  }

  &__demographicsPercentBar {
    background-color: rgba(0, 0, 0, 0.1);
    height: 6px;
    margin-top: 4px;
    display: block;
    width: 100%;
    div {
      display: block;
      float: left;
      height: 6px;
    }
  }
  ul {
    padding-left: 16px;
  }
  .GameIcon {
    margin: 0 8px 8px 0;
  }
  .GRTooltip {
    display: inline-block;
  }
}
