@import '../../../../variables';

$node-size: 22px;
$node-size-small: 16px;
$node-border-width: 3px;
$node-border-width-small: 2px;
$node-border-width-big: 4px;
$node-card-width: 200px;

$icon-size: 50px;

.MarketExplorerGamesChartNodeItem {
  position: absolute;
  width: $node-size;
  height: $node-size;
  transform: translate(calc($node-size/2), calc($node-size/-2));
  border-radius: 50%;
  background-color: #888;
  border-color: #888;
  border-width: $node-border-width;
  border-style: solid;
  background-size: cover;
  box-sizing: border-box;
  z-index: 1;

  &:hover {
    z-index: 3;
  }

  &--DefaultBG {
    width: $node-size-small;
    height: $node-size-small;
    border-width: $node-border-width-small;
    transform: translate(calc($node-size-small/2), calc($node-size-small/-2));
    border-color: white !important;
  }

  &__Data {
    position: absolute;
    width: $icon-size;
    height: $icon-size;
    top: 50%;
    left: 50%;
    margin-left: calc($icon-size/-2);
    margin-top: calc($icon-size/-2);
    animation: zoomInAnimation 0.3s;
    z-index: 1;

    &__Icon {
      position: relative;
      width: $icon-size;
      height: $icon-size;
      background-color: #888;
      border-color: #888;
      border-width: $node-border-width-big;
      border-style: solid;
      background-size: cover;
      border-radius: 22%;
      box-sizing: border-box;
      z-index: 3;
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
    }

    &__Card {
      position: absolute;
      width: 200px;
      padding: 10px 15px;

      &--TopLeft {
        border-top-left-radius: 0px;
        top: 0px;
        left: $icon-size - 8px;
      }

      &--TopRight {
        border-top-right-radius: 0px;
        top: 0px;
        right: $icon-size - 8px;
      }

      &--BottomLeft {
        border-bottom-left-radius: 0px;
        bottom: 0px;
        left: $icon-size - 8px;
      }

      &--BottomRight {
        border-bottom-right-radius: 0px;
        bottom: 0px;
        right: $icon-size - 8px;
      }

      &__Name {
        font-size: 14px;
      }

      &__Publisher {
        font-size: 12px;
        color: $light-color;
      }
    }
  }
}

.MarketExplorerGamesChartNodeItemColor {
  z-index: 2;

  &--1 {
    background-color: $segment-color-1;
    border-color: $segment-color-1;
  }

  &--2 {
    background-color: $segment-color-2;
    border-color: $segment-color-2;
  }

  &--3 {
    background-color: $segment-color-3;
    border-color: $segment-color-3;
  }

  &--4 {
    background-color: $segment-color-4;
    border-color: $segment-color-4;
  }

  &--5 {
    background-color: $segment-color-5;
    border-color: $segment-color-5;
  }

  &--6 {
    background-color: $segment-color-6;
    border-color: $segment-color-6;
  }

  &--top {
    background-color: $segment-color-top-game;
    border-color: $segment-color-top-game;
  }
}

@keyframes zoomInAnimation {
  from {
    transform: scale(0.5);
  }
  to {
    transform: scale(1);
  }
}
