.MarketSelector {
  .MuiFormControl-root {
    width: 94px;
  }

  .flag {
    font-size: 18px;
    margin-top: -2px;
    margin-right: 8px;
    vertical-align: middle;
  }

  &__MenuItem {
    .flag {
      font-size: 18px;
      margin-right: 8px;
      vertical-align: middle;
    }
  }
}
