@import '../../../../variables';

.GameSearchRecentResult {
  &__GameSearchResult {
    padding: 15px 0px;
    text-align: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    color: $light-text-color;
  }
}
