@import '../../../../variables';

.MarketExplorerSegmentColorBadge {
  border-radius: 50%;

  &--1 {
    background-color: $segment-color-1;
  }

  &--2 {
    background-color: $segment-color-2;
  }

  &--3 {
    background-color: $segment-color-3;
  }

  &--4 {
    background-color: $segment-color-4;
  }

  &--5 {
    background-color: $segment-color-5;
  }

  &--6 {
    background-color: $segment-color-6;
  }

  &--top {
    background-color: $segment-color-top-game;
  }
}
