@import '.././../../../variables';

.MotivationTable {
  td,
  th {
    border-right: 1px solid $divider-default-color;
    &:last-child {
      border-right: 0 none;
    }
  }
  .GRDotRating {
    display: inline-block;
    vertical-align: bottom;
    margin-right: 0.5em;
  }
  .MotivationLabel {
    display: inline-flex;
    p {
      font-size: 12px;
    }
    button {
      font-size: 12px;
    }
    svg {
      vertical-align: bottom;
      margin: 4px 12px 0 4px;
    }
    .GRTooltip {
      display: inline-flex;
      align-items: center;
      margin-right: 6px;
    }
  }
  &__iconWrapper {
    &:hover {
      border-color: transparentize($primary-color, 0.5);
    }
    .GameIcon {
      vertical-align: middle;
      margin-left: 8px;
    }
    &.selected {
      .GameIcon {
        box-shadow: 0 0 0 2px $primary-color;
      }
    }
  }
}

.FeatureEffectModal {
  .MotivationSelection button {
    margin: 0 4px 4px 0;
    text-transform: capitalize;
  }
}
