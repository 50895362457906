.ImageCropDialog {
    &__CropperContainer {
        position: relative;
        width: 100%;
        height: calc(100vh - 320px);
    }

    &__PreviewContainer {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;

        &--rounded {
            border-radius: 22%;
            overflow: hidden;
        }

        .output {
            position: relative;
            width: 300px;
            overflow: hidden;
            box-shadow: 0 0 32px rgba(0, 0, 0, 0.3);

            img {
                position: absolute;
                top: 0;
                left: 0;
                transform-origin: top left;
              }
          
        }
    }
}