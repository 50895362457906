@import '../../../../variables.scss';

.collectionMenu {
  width: 320px;
  z-index: 1000000;

  li:hover {
    background-color: $app-background-color;
  }
  p.divider-overlay {
    color: $subtle-color;
    font-size: 12px;
    letter-spacing: 1px;
    text-transform: uppercase;
  }
  .loadingCollections {
    span {
      margin: 4px auto 8px;
    }
  }
  .createCollection {
    margin: 16px;
    width: 256px;
    .MuiFormGroup-root {
      margin: 16px 0;
    }
    .MuiRadio-root {
      padding: 4px 8px;
    }
  }
  &__listItem {
    .MuiTypography-root {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
