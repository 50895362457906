.SegmentSection {
  .collapseArrow {
    transition: transform 0.1s ease-in-out;
    transform: rotate(0deg);

    &.isCollapsed {
      transform: rotate(180deg);
    }
  }
}
