@import '../../../../variables';

.AccountBadge {
  display: block;
  margin-right: -3px;

  &__icon {
    width: 18px;
    margin-right: 2px;
    margin-top: -4px;
  }
}

#AccountBadgePopover {
  margin-top: -25px;

  .AccountBadge__popover {
    text-align: center;
    width: 200px;

    &__header {
      padding: 10px;
      background-color: rgba(0, 0, 0, 0.03);
    }

    &__user-name {
      font-weight: bold;
      font-size: 16px;
    }

    &__organization-name {
      font-size: 14px;
      color: $light-color;
    }

    &__content {
      padding: 10px;
      border-top: 1px solid rgba(0, 0, 0, 0.1);
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      text-align: left;
    }

    &__footer {
      padding: 10px;
      background-color: rgba(0, 0, 0, 0.03);
    }
  }
}
