@import '../../../../variables';

.GameKeyIndicator {
  &--Small {
    .GameKeyIndicator {
      &__Title {
        font-size: 11px;
      }

      &__Container {
        font-size: 11px;

        .MuiSvgIcon-root {
          font-size: 14px !important;
        }
      }
    }
  }

  &__Title {
    font-weight: bold;
    font-size: 12px;
    padding-bottom: 5px;
    color: $light-text-color !important;
  }

  &__Container {
    padding: 5px 0px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    font-size: 14px;

    &:last-child {
      border-bottom: none;
    }
  }
}
