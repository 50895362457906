@import 'src/variables';

.VisualExplorerOverview {
  .MuiTableContainer-root {
    .MuiTableCell-head {
      border-color: $subtle-color;
      font-size: 12px;
      font-weight: bold;
      padding-top: .25em;
      padding-bottom: .5em;
      text-transform: uppercase;
      .MuiTableSortLabel-root {
        padding-left: 0;
        svg {
          margin-right: -1em;
        }
      }
    }
    tbody tr:hover {
      background-color: lighten($divider-default-color, 80%);
    }
    td {
      border-color: $divider-default-color;
      line-height: 26px;
      padding: 0.15em 0;
      a {
        color: inherit;
        cursor: pointer;
        text-decoration: none;
        &:hover {
          color: $primary-color;
        }
      }
    }
  }
}
