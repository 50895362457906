@import '../../../variables';

.collectionPreview {
  width: 300px;
  display: inline-block;
  margin: 8px;
  position: relative;
  transition: box-shadow 0s;
  p {
    color: $dark-text-color;
  }
  .MuiCardMedia-root {
    background-color: $app-background-color;
    height: 223.5px;
    overflow: hidden;
    span {
      color: darken($app-background-color, 7.5%);
      display: block;
      margin: 90px auto 0;
      text-align: center;
      width: 75%;
      &.ribbon {
        background-color: $primary-color;
        box-shadow: $card-box-shadow;
        color: white;
        font-weight: bold;
        font-size: 10px;
        left: -35px;
        letter-spacing: 1px;
        line-height: 2em;
        margin: 0;
        padding-top: 1px;
        position: absolute;
        text-align: center;
        text-transform: uppercase;
        top: 25px;
        transform: rotate(-45deg);
        width: 150px;

        &-public {
          background-color: $secondary-color;
        }
      }
    }
    div {
      background-position: center center;
      background-size: cover;
      box-sizing: border-box;
      float: left;
      height: 50%;
      width: 50%;
      &.image-0 {
        border-right: 2px solid white;
        height: 100%;
      }
      &.image-2 {
        border-top: 2px solid white;
      }
    }
    &.images-1 {
      div.image-0 {
        border-right: 0 none;
        width: 100%;
      }
    }
    &.images-2 {
      div.image-1 {
        height: 100%;
      }
    }
  }
  &:hover {
    box-shadow: 0 0 0 1px $primary-color;
    cursor: pointer;
  }
}
