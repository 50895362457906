@import '../../variables';

.Analyst {
  &__Title {
    color: $light-color;
  }

  .icon {
    background-color: $secondary-color;
  }
}
