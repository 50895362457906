@import '../../../../variables';

.FeatureTabView {
  &__icon {
    border: 1px solid $primary-color;
  }
}

.StickyGameIconBar {
  &__container {
    box-shadow: $card-box-shadow;
    transition: all 0.4s ease;
  }
  &__iconContainer {
    transition: all 0.4s ease;
  }
}

.GameComparisonTable {
  &__rowContentIcon {
    font-size: 15px;
  }
  &__gameIconContainer {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  &__gameIconWrapper {
    border: 2px solid transparentize($primary-color, 0.8);
    cursor: pointer;
    border-radius: $default-border-radius;
    &:hover {
      border-color: transparentize($primary-color, 0.5);
    }
    &.selected {
      border-color: $primary-color;
      position: relative;
      &::after {
        $arrow-size: 12px;
        content: '';
        position: absolute;
        left: 40%;
        top: 100%;
        width: 0;
        height: 0;
        border-left: $arrow-size solid transparent;
        border-right: $arrow-size solid transparent;
        border-top: $arrow-size solid $primary-color;
        clear: both;
      }
    }
  }
  &__choiceText {
    font-size: 0.8rem;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    &.zerovalue {
      opacity: 0.4;
    }
  }
}
