@import '../../../../variables.scss';

.previewContainer {
  .MuiCardHeader-content small {
    color: $light-color;
    display: block;
    font-size: 13px;
    margin-right: 4px;
  }
}

.screenshotPreviews {
  margin: -8px -8px -12px;
  span.MuiBadge-badge {
    border-radius: 50%;
    font-size: 16px;
    top: 10px;
    right: 10px;
    height: 32px;
    min-width: 32px;
  }
  fieldset {
    background-color: lighten($app-background-color, 3.5%);
    border-radius: $default-border-radius;
    border: 1px solid $app-background-color;
    margin: 8px 8px 4px;
  }
  legend .versionTitle {
    background-color: white;
    border-radius: 6px;
    color: $light-text-color;
    font-size: 15px;
    padding: 4px 8px;
  }

  .MuiTooltip-tooltipPlacementTop {
    transform: translateY(-15px) !important;
  }
}

.preview {
  width: 300px;
  display: inline-block;
  margin: 10px;
  position: relative;
  transition: box-shadow 0s;

  @media (min-width: $breakpoint-lg) {
    width: 370px;
  }

  @media (max-width: 1600px) {
    width: 310px;
  }
  @media (max-width: 1280px) {
    width: 390px;
  }
  @media (max-width: $breakpoint-lg) {
    width: 350px;
  }
  @media (max-width: $breakpoint-xs) {
    width: 350px;
  }
  &:hover {
    box-shadow: 0 0 0 1px $primary-color;
    cursor: pointer;
    .imageContainer {
      img {
        transform: scale(1.05);
      }
      button.MuiButton-root {
        opacity: 1;
      }
    }
  }
  .imageContainer {
    background-color: $app-background-color;
    height: 223.5px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      max-height: 230px;
      max-width: 100%;
      transition: transform 0.3s ease-out;
    }
    .MuiButton-root {
      display: inline-block;
      min-width: 24px;
      height: 36px;
      opacity: 0;
      padding: 6px;
      position: absolute;
      right: 8px;
      top: 8px;
      transition: opacity 0.3s ease-out;
      span.MuiButton-startIcon {
        line-height: 0;
        margin: 0;
        min-width: 24px;
      }
      &.remove {
        top: 179px;
      }
    }
  }
  .latest {
    background-color: $primary-color;
    box-shadow: $card-box-shadow;
    color: white;
    display: block;
    font-weight: bold;
    font-size: 11px;
    left: -45px;
    letter-spacing: 1px;
    line-height: 2em;
    padding-top: 1px;
    position: absolute;
    text-align: center;
    text-transform: uppercase;
    top: 15px;
    transform: rotate(-45deg);
    width: 150px;
  }
  .MuiCardActions-root {
    background-color: white;
    position: relative;
    z-index: 4;
  }
  h3 {
    font-size: 18px;
    font-weight: bold;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  p.artist {
    color: $light-color;
    font-size: 12px;
    margin-bottom: 0.1em;
  }
  p.version {
    font-size: 12px;
  }
  .commentIcon {
    bottom: 12px;
    color: $primary-color;
    right: 10px;
    position: absolute;
  }
}
