@import 'src/variables';

.MarketExplorerFeaturesData {
  .MuiCardContent-root {
    padding: 8px 16px;
  }

  .SortAndSelection {
    p {
      display: inline-block;
      line-height: 42px;
      vertical-align: middle;
    }
    .SortOption {
      > * {
        vertical-align: text-bottom !important;
      }
      background: white;
      border-radius: 24px;
      border: 1px solid #ccc;
      display: inline-block;
      line-height: 30px;
      margin-left: 0.5em;
      padding: 0.25em 0.75em;
      &:hover {
        box-shadow: 0 0 0 1px $segment-color-1;
        cursor: pointer;
        .SortBadge {
          opacity: 0.75;
        }
      }
      .SortBadge {
        display: inline-block;
        margin-right: 0.5em;
        position: relative;
        .MarketExplorerSegmentColorBadge {
          height: 16px;
          width: 16px;
        }
        span {
          border-color: $segment-color-1 transparent transparent;
          border-style: solid;
          border-width: 5px 4px;
          bottom: -13px;
          left: 4px;
          position: absolute;
          transition: all 100ms;
          &.SortMark--up {
            bottom: -7px;
            transform: rotate(180deg);
          }
        }
      }
      &--2 .SortBadge span {
        border-top-color: $segment-color-2;
      }
      &--3 .SortBadge span {
        border-top-color: $segment-color-3;
      }
      &--4 .SortBadge span {
        border-top-color: $segment-color-4;
      }
      &--5 .SortBadge span {
        border-top-color: $segment-color-5;
      }
      &--6 .SortBadge span {
        border-top-color: $segment-color-6;
      }
    }
  }
  .FeatureDataTableParams {
    button .MuiChip-root {
      margin: 0 -8px 0 8px;
    }
  }
  .FeatureDataTable {
    margin-top: 0;
    tr td:first-child {
      background-color: white;

      &::before {
        border-bottom: 1px solid #fff;
        content: '';
        position: absolute;
        width: 100%;
        bottom: -1px;
        left: 0;
      }
    }

    tr.MainSegment {
      border-top: 2px solid $divider-default-color;
      td:first-child {
        &::before {
          border-bottom: 1px solid #fff;
        }
      }
    }
    p {
      font-size: 14px;
    }
    .FeatureLabelHeader {
      .MuiCheckbox-root {
        padding: 0 4px 0 8px;
      }
      p {
        font-size: 12px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      svg {
        font-size: 14px;
        margin-right: 0.25em;
      }
    }
    .FeatureLabel {
      padding-left: 12px;
      text-align: left;
      .GroupDefinition {
        display: block;
        margin-bottom: 1px;
        bottom: 0;
        left: 0;
        position: absolute;
        top: 0;
        width: 5px;
      }
    }
    thead tr {
      &:first-child {
        height: 42px;
      }
      .HeaderCell_root {
        padding: 8px;
      }
      th {
        text-transform: capitalize;
        &:first-child {
          text-align: left;
        }
        .MuiFormControl-root {
          height: 0;
          margin-left: 8px;
          margin-top: 2px;
          input {
            height: 36px;
          }
        }
        fieldset {
          width: 94%;
          height: 42px;
        }
        svg,
        p {
          vertical-align: middle;
        }
      }
    }
    td {
      &:first-child {
        border-right: 1px solid $divider-default-color;
      }
      &:last-child {
        border-right: 0 none;
      }
      p.success {
        color: $success-color;
      }
      p.warning {
        color: $warning-color;
      }
      .MuiSvgIcon-root {
        font-size: 16px;
        &.InDifferentiating {
          font-size: 12px;
        }
      }
      .ProgressBar .hoverable:hover {
        .MuiLinearProgress-barColorPrimary {
          background-color: lighten($primary-color, 10%);
        }
        cursor: pointer;
      }
      .SegmentGroupIcon-1 {
        color: $segment-color-1;
      }
      .SegmentGroupIcon-2 {
        color: $segment-color-2;
      }
      .SegmentGroupIcon-3 {
        color: $segment-color-3;
      }
      .SegmentGroupIcon-4 {
        color: $segment-color-4;
      }
      .SegmentGroupIcon-5 {
        color: $segment-color-5;
      }
      .SegmentGroupIcon-6 {
        color: $segment-color-6;
      }
    }
  }
  &__motivationTitle {
    text-transform: capitalize;
    font-weight: bold;
    margin-top: 16px;
  }
}
