@import '../../../../../variables';

.MarketChip {
  background-color: rgba(0, 0, 0, 0.08);
  border-width: 2px;

  &:not(.selected) {
    border-color: transparent;
  }

  &.selected {
    .icon {
      color: $success-color;
    }
  }

  .flag {
    margin: 0 0.25rem;
  }
}
