@import '../../../../variables';

.root {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: $app-background-color;
  background-image: linear-gradient(180deg, rgba(255, 255, 255, 0.49) 23%, rgba(255, 255, 255, 0) 77%);

  .loader {
    position: absolute;
    width: 100%;
    top: 50%;
    transform: translateY(-50%);
    text-align: center;

    .logo {
      padding: 20px 0px 10px;
      text-align: center;
      margin: 0 auto 20px;

      img {
        width: 180px;
      }
    }

    .CircularProgress {
      display: inline-block;
    }
  }
}
