@import '../../../../variables';

.GameHeader {
  &__header {
    position: relative;
    margin-top: 5px;
    overflow: hidden;
    &.withContent {
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;
    }
  }

  &__content {
    top: 89px;
    z-index: 91;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    margin-top: 0px;
    border-top: 1px solid rgba(255,255,255,0.7);

    &--mobile {
      position: relative;
      top: auto;
    }
    &.sticky {
      position: sticky;
    }
  }

  &__ribbon {
    background-color: $secondary-color;
    color: white;
    font-weight: bold;
    text-align: center;
    padding: 5px 10px;
    font-size: 12px;
    position: absolute;
    left: -27px;
    top: 21px;
    transform: rotate(-45deg);
    width: 100px;
    text-transform: uppercase;
    z-index: 1;

    &--soft-launch {
      background-color: $light-text-color;
      font-size: 10px;
    }
  }

  &__game-details {
    padding: 12px;

    .GameIcon {
      &:hover {
        position: relative;
        z-index: 2;
      }
    }

    &__name {
      font-weight: bold;
      font-size: 22px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    &__publisher {
      font-size: 16px;

      .PublisherGamesDialogLink__Link {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }

    &__subgenre {
      padding-top: 13px;
    }

    &__powerscore {
      padding: 0px 15px;
    }

    &__actions {
      @media (max-width: $breakpoint-sm) {
        text-align: left;
      }
      text-align: right;

      button {
        margin: 3px 5px;
      }
    }

    &__analyzed-time {
      text-align: right;
      @media (max-width: $breakpoint-sm) {
        text-align: left;
      }
      margin-bottom: 10px;

      .Chip {
        &--warning {
          color: $warning-color;
          border-color: $warning-color;
          font-size: 14px !important;

          svg {
            margin-left: 2px;
          }

          path {
            color: $warning-color;
          }
        }
      }
    }
  }
}
