@import '../../../../variables';

.gameContainer {
  display: inline-flex;
}

.gameName {
  font-weight: bold;
  font-size: 14px;
  margin-top: 3px;
  color: $dark-text-color;
}

.gamePublisher {
  font-size: 13px;
  color: $light-text-color;
  margin-top: 2px;
}

.subgenreChip {
  font-size: 0.6875rem;
  margin: 0px;

  span {
    white-space: normal;
  }
}

.container {
  overflow: auto;
  width: 100%;
}

.rank-value {
  font-size: 18px;
  font-weight: bold;

  &--outside {
    color: $light-color;
  }
}

.no-permission-content-style {
  opacity: 0.6;
}
