@import '../../../../variables';
.NewsList {
  &__header {
    display: flex;
    align-items: center;
  }

  &__subgenre {
    border-bottom: 1px solid rgba(160, 110, 110, 0.1);
    margin-bottom: spacing(2);
    padding-bottom: spacing(1);

    .SelectedSubgenreChips {
      margin-top: spacing(2);
    }
  }
}
