@import '../../../variables';

.GoogleSignupButton {
  border-radius: 46px;
  padding-top: 1px;
  padding-bottom: 1px;
  background-color: $google-signup-button-color;

  &:hover {
    background-color: $google-signup-button-hover-color;
  }

  &__logo {
    background-image: url('/assets/images/google-icon.png');
    background-size: 38px;
    background-position: 50%;
    background-repeat: no-repeat;
    background-color: $white-color;
    height: 48px;
    width: 48px;
    border-bottom-left-radius: 48px;
    border-top-left-radius: 48px;
    position: relative;
    left: -15px;
  }
}
