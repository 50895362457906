.GameAddNewModal {
  &__loadingCover {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
    background: rgba(119, 119, 119, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
