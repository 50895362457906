@import '../../variables';

.FollowedGamesPage {
  &__header {
    &__followed-games-mode {
      margin-top: 10px;
      margin-bottom: 10px;

      .MuiRadio-root {
        margin-right: 10px;
      }
    }

    &__help-text {
      color: $light-text-color;
      font-size: 14px;
    }
  }

  &__add-game-button {
    color: #888;
    font-size: 24px;
    vertical-align: middle;
    border: 2px dashed #888;
    min-height: 114px;
    height: 100%;
    border-radius: $default-border-radius;
    box-sizing: border-box;
    padding-top: 10px;
    background-color: rgba(0, 0, 0, 0.03);
    cursor: pointer;

    &:hover {
      border-color: $primary-color;
      color: $primary-color;
      transition: all 0.15s ease-in-out;
    }

    .MuiSvgIcon-root {
      position: relative;
      top: 2px;
    }
  }
}
