.ReportCardPreview {
  .chip {
    margin: 0px 8px 10px 0px;
  }

  .divider {
    margin-right: 10px;
    margin-top: 2px;
    height: 20px;
  }

  .MuiAvatar-root {
    width: 60px;
    height: 60px;
  }

  .MuiAvatarGroup-root {
    .GRTooltip {
      margin-left: -8px;
    }
  }

  .MuiCardHeader-root span {
    font-size: 1.3rem;
  }
}
