@import '../../../../variables';

.GameIcon {
  overflow: hidden;
  border-radius: 22%;
  display: inline-block;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  background-color: #ddd;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.15);
  font-size: 16px;
  position: relative;

  img {
    box-sizing: border-box;
    width: 100%;
  }

  &--xtiny {
    width: 24px;
    height: 24px;
    font-size: 0.75rem;
  }

  &--tiny {
    width: 32px;
    height: 32px;
    font-size: 1rem;
  }

  &--small {
    width: 42px;
    height: 42px;
  }

  &--normal {
    width: 62px;
    height: 62px;
  }

  &--large {
    width: 80px;
    height: 80px;
  }

  &--huge {
    width: 100px;
    height: 100px;
  }

  &--selected {
    img {
      border: 3px solid $primary-color;
      transition: border-width 0.1s ease-in;
    }
  }
  &--disabled {
    img {
      filter: grayscale(0.6);
    }
  }

  &--Count {
    background-color: $secondary-color;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-weight: bold;
  }

  &__TypeIndicator {
    position: absolute;
    bottom: 0px;
    left: 0px;
    width: 20px;
    height: 20px;
    background-color: $secondary-color;
    color: white;
    border-top-right-radius: 6px;
    text-align: center;
    box-sizing: border-box;
    padding-top: 2px;

    .MuiSvgIcon-root {
      font-size: 14px;
    }
  }
}
