@import '../../../../variables';

.PublisherGamesDialogLink {
  &__Link {
    &--Active {
      cursor: pointer;
      &:hover {
        color: $primary-color;
      }
    }
  }
}
