@import '../../../../../variables';

.LiveEventsGameAlertCard {
  padding: 10px;

  &__Name {
    display: block;
    font-weight: bold;
    font-size: 15px;
    margin-bottom: 3px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  &__Description {
    font-size: 14px;
    margin-bottom: 8px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  &__Date {
    font-size: 13px;
    color: $light-text-color;
    margin-bottom: 10px;
  }
}
