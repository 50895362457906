@import '../../../../../variables';

.NewsCardHeader {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding-bottom: 5px;
  margin-bottom: 15px;

  &__game-name {
    display: flex;
    justify-items: center;
    font-size: 18px;
    font-weight: bold;
  }

  &__game-info {
    margin-left: 5px;
    &:hover {
      cursor: pointer;
    }
  }

  &__publisher {
    font-size: 12px;
    color: $light-color;
  }

  &__subgenre {
    margin-top: 7px;

    .Chip {
      font-size: 11px;
    }
  }

  &__rank {
    font-weight: bold;
    font-size: 20px;
  }

  &__rank-text {
    font-weight: bold;
    color: $light-color;
    font-size: 14px;
    margin-bottom: 10px;
  }
}
