.OrganizationPage {
  .Card {
    margin-bottom: 15px;

    h2 {
      margin-bottom: 0px;
    }
  }

  .GridContainer {
    margin-bottom: 15px;
    padding-bottom: 10px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);

    &:last-child {
      margin-bottom: 0px;
      padding-bottom: 0px;
      border-bottom: 0px solid rgba(0, 0, 0, 0.1);
    }
  }
}
