@import '../../../variables';

.row {
  .cell {
    color: $dark-text-color;
  }

  &:first-child {
    .cell {
      padding-bottom: 0.25rem;
    }
  }

  &:last-child {
    .cell {
      padding-top: 0.25rem;
    }
  }

  &:only-child {
    .cell {
      padding: 1em 0;
    }
  }

  &:not(:last-child) .cell {
    border-bottom: none;
  }
}
