.GameIconAttributeModal {
  .MuiDialogTitle-root span {
    text-transform: capitalize;
  }
  .MuiDialog-paper {
    min-height: 80%;
  }
  .MuiFormControl-root {
    margin-left: 8px;
  }
}
