@import 'src/variables';

.GameNameStatsModal {
  .wordStats {
    p {
      font-weight: 900;
    }
    label {
      color: $light-color;
      font-weight: 100;
    }
  }
  .verticalDividers {
    border-style: solid;
    border-color: $divider-default-color;
    border-width: 0 1px;
  }
  .iconList {
    margin: 0 -12px -8px 0;
    .MuiCard-root {
      border-radius: $default-border-radius + 4px $default-border-radius + 4px 8px 8px;
      display: inline-block;
      margin: 0 12px 8px 0;
      padding: 4px;
      width: 100px;
      p {
        color: $light-text-color;
        font-size: 12px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
}
