@import '../../variables';

.screenshotListModal {
    a {
        color: inherit;
    }
    h3 {
        font-size: 21px;
    }
    .artist {
        color: $light-color;
        font-size: 15px;
    }
    .imageContainer {
        position: relative;
        .AddToCollectionButton-root {
            display: inline-block;
            min-width: 24px;
            height: 36px;
            opacity: 0;
            padding: 6px;
            position: absolute;
            right: 8px;
            top: 24px;
            transition: opacity .3s ease-out;
            span.MuiButton-startIcon {
                line-height: 0;
                margin: 0;
                min-width: 24px;
            }
        }
        .commentIcon {
            background-color: white;
            position: absolute;
            bottom: 12px;
            right: 8px;
            svg {
                color: $primary-color;
            }
        }
        &:hover {
            .AddToCollectionButton-root {
                opacity: 1;
            }
        }
    }
    img {
        box-shadow: $card-box-shadow;
        border-radius: 6px;
        cursor: pointer;
        max-width: 100%;
    }
}
