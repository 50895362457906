@import 'src/variables';

.FeatureModalContent {
    h3 .MuiSvgIcon-root {
        color: $warning-color;
        margin: 0 8px 0 0;
        vertical-align: text-bottom;
    }
    h3.WhatItMeans .MuiSvgIcon-root {
        color: $success-color;
    }
    .ExplanationImage {
        height: 198px;
        width: 198px;
        object-fit: cover;
    }
    .ExplanationCard .MuiCardContent-root {
        cursor: pointer;
        h3 {
            margin-bottom: 16px;
        }
        &:last-child {
            padding-bottom: 0;
        }
    }
    .BiggerExplanations .ExplanationImage {
        background-color: $secondary-color;
        background-size: contain;
        height: 398px;
        width: 50%;
    }
}
