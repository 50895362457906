.PricingDataFlexContainer {
    .item {
        flex-grow: 1;
        padding-left: 16px;
        padding-top: 16px;
    }

    .item-large {
        flex-grow: 3.7;
    }

    .item-small {
        flex-grow: 0.9;
        align-self: center;
    }
    display: flex;
    width: 100%;
}
