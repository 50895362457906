@import './variables';

.App {
  position: relative;
  height: 100%;
}

.top-margin {
  margin-top: 25px !important;
}

.small-top-margin {
  margin-top: 15px !important;
}

.left-margin {
  margin-left: 25px !important;
}

.right-margin {
  margin-right: 25px !important;
}

.bottom-margin {
  margin-bottom: 10px !important;
}

.bottom-margin-none {
  margin-bottom: 0px !important;
}

.top-padding {
  padding-top: 25px !important;
}

.vertical-margin {
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}

.horizontal-margin {
  margin-right: 20px !important;
  margin-left: 20px !important;
}

.text-center {
  display: block;
  text-align: center;
}

.light-text-color {
  color: $light-color;
}

.destructive-button {
  background-color: $warning-color !important;
}

.Chip {
  &--primary-light {
    background-color: lighten($primary-color, 30%) !important;
  }
  &--secondary-light {
    background-color: lighten($secondary-color, 65%) !important;
  }
}

.MuiButton {
  &-root .amount {
    background-color: $primary-color;
    border-radius: 1em;
    color: white;
    display: inline-block;
    font-size: 0.85em;
    line-height: 1.6em;
    margin: 0 0 0 0.5em;
    min-width: 1em;
    padding: 0.1em 0.35em 0;
    text-align: center;
  }
  &-containedPrimary .amount {
    background-color: white;
    color: $primary-color;
  }

  &-containedWarning .amount {
    background-color: white;
    color: $warning-color;
  }
}

.MuiAutocomplete {
  &-popper {
    line-height: 1em;
  }
  &-groupLabel {
    color: $primary-color;
    margin-left: 4px;
    padding: 0 8px;
  }
}
