@import '../../../../variables';

.GameDashboardCard {
  margin-bottom: 15px;

  &__content-block {
    padding: 15px 15px;
  }

  &__container {
    @media (max-width: $breakpoint-md) {
      flex-direction: column;
    }
  }

  &__header {
    padding-bottom: 10px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }

  &__left {
    border-right: 1px solid rgba(0, 0, 0, 0.1);
  }

  &__ribbon {
    position: absolute;
    transform: rotate(-45deg);
    top: 20px;
    left: -12px;
    width: 80px;
    text-align: center;
    font-size: 10px;
    text-transform: uppercase;
    font-weight: bold;
    padding: 3px 0px 2px;
    background-color: $secondary-color;
    color: #fff;
    box-shadow: $card-box-shadow;
    z-index: 1;
  }
}
