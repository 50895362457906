@import '../../variables';

.root {
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
}

.positive {
  color: $success-color;
}

.negative {
  color: $warning-color;
}

.neutral {
  color: $ultra-light-text-color;
  padding-right: 10px;
}
