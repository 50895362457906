@import '../../variables';

.UpdatedFeaturesList,
.ChangedFeaturesList {
  .UpdatedFeature,
  .ChangedFeature {
    color: $light-text-color;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    padding: 0.625rem;

    &:last-child {
      border-bottom: 0 none;
      padding-bottom: 0px;
    }

    &.table {
      border-bottom: none;
      padding: 0 0 0.2rem 0;
    }

    &.card {
      .choices {
        font-size: 1.25rem;
      }
    }

    .action {
      &:hover {
        cursor: pointer;
        color: $primary-color;
      }
    }

    .choices {
      text-align: center;

      &.subtle {
        color: $light-color;
      }
    }
  }
}
