@import 'src/variables';

.FeatureModalContent {
    .MuiTable-root {
        .GameIconName {
            display: block;
            margin-left: 4px;
            text-align: left;
        }
        .GameIcon {
            display: inline-block;
            margin-right: 12px;
            margin-top: 4px;
            padding: 0;
            vertical-align: top;
        }
        .GameName {
            display: inline-block;
            h5 {
                color: $secondary-color;
                margin-bottom: .25em;
            }
        }
    }
}
