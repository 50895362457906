@import '../../../../../variables';

.NewsCardRibbon {
  position: absolute;
  transform: rotate(45deg);
  top: 26px;
  right: -26px;
  width: 120px;
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  padding: 3px 0px;
  background-color: $primary-color;
  color: #fff;
  box-shadow: $card-box-shadow;
}
