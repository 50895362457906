@import '../../variables';

.SideNavigationGroup {
  &.side-navi-group {
    margin-top: 20px;
    margin-bottom: 10px;

    &:first-child {
      margin-top: 0px;
    }
  }

  .side-navi-header {
    display: block;
    font-weight: bold;
    color: $dark-text-color;
    text-transform: uppercase;
    font-size: 14px;
    margin-bottom: 10px;

    &:hover,
    &--active {
      color: $primary-color;
      font-weight: bold;
    }
  }

  .side-navi-item {
    display: block;
    color: rgba(47, 62, 89, 1);
    font-size: 14px;
    padding-left: 20px;
    margin-top: 8px;

    &:hover,
    &--active {
      color: $primary-color;
      font-weight: bold;
    }
  }

  .side-navi-item__icon, 
  .side-navi-item__mui-icon {
    display: inline-block;
    margin-right: 8px;
    width: 16px;
    height: 16px;
    vertical-align: middle;
    margin-bottom: 4px;
  }

  .badge-item {
    margin: 4px 5px 2px 0px;
  }

  .side-navi-item:hover .side-navi-item__icon,
  .side-navi-item--active .side-navi-item__icon {
    filter: invert(66%) sepia(23%) saturate(5498%) hue-rotate(268deg) brightness(89%) contrast(79%);
  }
}
