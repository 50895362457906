@import '../../../../variables';

.GameMotivationPieChart {
  &__container {
    height: 100%;
    .motivationPieLockedImg {
      max-width: 100%;
      max-height: 100%;
      filter: blur(5px);
    }
  }

  &__header {
    background-color: $success-color;
    color: white;

    &__avatar {
      background-color: $success-color;
    }
  }
}
