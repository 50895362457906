.LiveEventsPricePointSelector {
    margin: 10px 0px 10px;
  
    &__Chips {
      margin-top: 10px;
    }
  
    &__OpenCloseToggle {
      cursor: pointer;
    }
  
    &__ChipsOpenToggle {
      transition: rotate 0.1s ease-in-out;
  
      &--Open {
        rotate: 180deg;
      }
    }
  }