@import '../../../variables';

.AIExampleCard {
  .text {
    span {
      font-size: 14px !important;
    }
  }

  .send {
    opacity: 0;
  }

  &:hover {
    background-color: darken(white, 2%);
    cursor: pointer;
    .send {
      visibility: visible;
      opacity: 1;
      color: $primary-color;
      transition: opacity 0.2s ease-in-out;
    }
  }

  &_no-hover {
    background-color: white;
    cursor: default;
    &:hover {
      background-color: white;
      cursor: default;
    }
  }
}
