.MoreScrollContentIndicator {
  &::before {
    transition: opacity 150ms ease-in-out;
    position: absolute;
    display: inline-block;
    z-index: 1;
    bottom: 80%;
    left: 0px;
    pointer-events: none;
    background-image: linear-gradient(to top, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1) 90%);
    width: 100%;
    height: 20%;
    content: '';
    opacity: 0;
  }

  &::after {
    transition: opacity 150ms ease-in-out;
    position: absolute;
    display: inline-block;
    z-index: 1;
    top: 80%;
    left: 0px;
    pointer-events: none;
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1) 90%);
    width: 100%;
    height: 20%;
    content: '';
    opacity: 0;
  }

  &.showTopGradient {
    &::before {
      opacity: 1;
    }
  }

  &.showBottomGradient {
    &::after {
      opacity: 1;
    }
  }
}
