@import '../../../../variables';

.root {
  margin-bottom: 1rem;

  &:last-child {
    margin-bottom: 0rem;
  }
}

.title {
  color: $light-color;
  margin-bottom: 0.3rem;
  padding-bottom: 0.3rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  text-transform: uppercase;
}
