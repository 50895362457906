@import '../../../../variables';

.TutorialVideosTabView {
  &__cardContainer {
    border: 1px solid $subtle-color;

    &:hover {
      border: 1px solid $primary-color;
      cursor: pointer;
    }
  }
}
