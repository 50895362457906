@import '../../variables';

.root {
  color: $light-color;
  display: inline-flex;

  .left-arrow {
    transform: translateX(0.5rem);
  }

  .right-arrow {
    transform: translateX(-0.5rem);
  }
}
