@import "src/variables";

.GREffectDots {
    display: inline-block;
    span {
        border-radius: 50%;
        border-style: solid;
        border-width: 1px;
        background-color: $app-background-color;
        border-color: darken($app-background-color, 5%);
        display: inline-block;
        height: 15px;
        margin: 0 0.125em;
        width: 15px;
    }
    &__small {
        span {
            height: 10px;
            width: 10px;
        }
    }
    p, span {
        vertical-align: middle;
    }
    p.value {
        font-size: 12px !important;
        margin-left: .35em;
    }
    .impact_6 {
        background-color: lighten(#f29f2c, 5%);
        border-color: #f29f2c;
    }
    .impact_5 {
        background-color: lighten(#f3ae29, 5%);
        border-color: #f3ae29;
    }
    .impact_4 {
        background-color: lighten(#f4be26, 5%);
        border-color: #f4be26;
    }
    .impact_3 {
        background-color: lighten(#f5cd24, 5%);
        border-color: #f5cd24;
    }
    .impact_2 {
        background-color: lighten(#f6dd21, 5%);
        border-color: #f6dd21;
    }
    .impact_1 {
        background-color: lighten(#f6dd21, 25%);
        border-color: lighten(#f6dd21, 20%);
    }
}
