@import '../../../../variables';
@import '../MarketExplorerGamesChartNodeItem/MarketExplorerGamesChartNodeItem.scss';

$chartBGColor: #e5e5e5;
$chartHeight: calc(100vh - 320px);
$chartMinHeight: 440px;
$chartMargin: 43px;
$chartBorder: 1px solid rgba(0, 0, 0, 0.05);

.MarketExplorerGamesChart {
  &__Header {
    padding: 15px 15px 5px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);

    &__Title {
      position: relative;
      font-weight: bold;
      color: $light-text-color;
      z-index: 1;
      padding-bottom: 10px;
      display: inline-flex;
      text-indent: 8px;

      span {
        color: $secondary-color;
        cursor: help;
      }
    }

    .MarketExplorerSegmentColorBadge {
      display: inline-block;
      vertical-align: middle;
      margin-top: 1px;
      margin-right: 8px;
    }

    .MuiFormLabel-root,
    .MuiFormControlLabel-label {
      font-size: 14px;
    }

    .MuiSelect-select {
      font-size: 12px;
      padding: 0px 10px;
    }

    .MuiCheckbox-root {
      padding: 0px 9px;
    }
  }

  &__text {
    font-weight: bold;
  }

  &__ChartWrapper {
    position: relative;
    height: $chartHeight;
    min-height: $chartMinHeight;
    padding-bottom: $chartMargin;
  }

  &__YAxisHelper {
    position: absolute;
    width: 100%;
    top: 0px;
    height: $chartMargin;
    left: 0px;
    right: $chartMargin;
  }

  &__Axis {
    position: absolute;
    padding: 4px 5px 4px 10px;
    box-sizing: border-box;

    &--Y {
      transform-origin: top left;
      width: $chartHeight;
      min-width: $chartMinHeight;
      height: $chartMargin;
      transform: rotate(-90deg) translateX(-100%);
    }

    &--X {
      top: auto;
      bottom: 0;
      left: $chartMargin;
      right: 0px;
      height: $chartMargin;
    }
  }

  &__Chart {
    position: relative;
    margin-left: $chartMargin;
    margin-bottom: $chartMargin;
    height: $chartHeight;
    min-height: $chartMinHeight;
    background-color: $chartBGColor;
    border-left: $chartBorder;
    border-bottom: $chartBorder;
    box-sizing: border-box;

    &__YDivider {
      position: absolute;
      top: 0px;
      bottom: 0px;
      left: 50%;
      width: 1px;
      background-color: rgba(0, 0, 0, 0.1);
    }

    &__XDivider {
      position: absolute;
      top: 50%;
      left: 0px;
      width: 100%;
      height: 1px;
      background-color: rgba(0, 0, 0, 0.1);
    }

    &__Message {
      position: absolute;
      transform: translate(-50%, -50%);
      top: 50%;
      left: 50%;
      padding: 20px;
      background-color: $chartBGColor;
      color: $light-text-color;
    }

    &__Inner {
      position: absolute;
      left: calc($node-size/2);
      top: calc($node-size/2);
      right: calc($node-size/2);
      bottom: calc($node-size/2);
    }
  }
}

.MarketExplorerGamesChartAxis {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
