@import '../../../../variables';

.PowerscoreBreakdown {
  &__selectedItem {
    &:hover {
      color: $primary-color;
      cursor: pointer;
    }
  }
}
