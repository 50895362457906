@import '../../variables';

.AppWrapper {
  &__background {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: $app-background-color;
  }

  &__wrapper {
    position: relative;
    max-width: $app-width;
    min-height: 100vh;
    margin: 0 auto;

    &--mobile {
      .AppWrapper {
        &__content {
          margin-left: 0px;
        }
      }
    }

    .Sidebar {
      &-enter {
        transform: translateX(0);
      }

      &-enter-active {
        transform: translateX(calc(-#{$side-navigation-width} - var(--sidebarGap)));
        transition: transform 300ms ease-in-out;
      }

      &-enter-done {
        transform: translateX(calc(-#{$side-navigation-width} - var(--sidebarGap)));
      }

      &-exit {
        transform: translateX(calc(-#{$side-navigation-width} - var(--sidebarGap)));
      }

      &-exit-active {
        transform: translateX(0);
        transition: transform 300ms ease-in-out;
      }

      &-exit-done {
        transform: translateX(0);
      }
    }

    &.fullscreen {
      &-enter {
        max-width: $breakpoint-fullscreen;
      }

      &-enter-active {
        max-width: 100vw;
        transition: max-width 400ms ease-out;
      }

      &-enter-done {
        max-width: 100vw;
      }

      &-exit {
        max-width: 100vw;
      }

      &-exit-active {
        max-width: $breakpoint-fullscreen;
        transition: max-width 400ms ease-in;
      }

      &-exit-done {
        max-width: $breakpoint-fullscreen;
      }
    }
  }

  &__content {
    position: relative;
    margin-left: 330px;
    padding: 0px 10px;
    transition: margin-left 300ms ease-in-out;

    &__inner {
      padding: 15px 10px 15px;
      min-height: calc(100vh - 105px);
      @media (max-width: 900px) {
        padding: 16px 0;
      }
    }

    &__sidebar--closed {
      margin-left: 30px;
    }
  }

  &__menu-toggle {
    position: fixed;
    top: 20px;
    left: 20px;
    z-index: 1000;
  }

  &__sidebar {
    position: fixed;
    z-index: 500;
    height: 100vh;
    transition: transform 0.3s ease-in-out;
  }

  &__content-fader {
    z-index: 499;
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    opacity: 0;
    background-color: #000;
    transition: opacity 0.3s ease-in-out;
    pointer-events: none;

    &--active {
      opacity: 0.5;
      pointer-events: all;
    }
  }
}
