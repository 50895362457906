.FeatureList {
  &__feature {
    background-color: white;
    border-radius: 24px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
    margin-bottom: 4px;
    margin-top: 5px;
    padding: 0 10px;
    > div {
      padding: 4px 0;
    }
    span.GRImg {
      display: inline-block;
      height: 25px;
      margin-right: 12px;
      width: 35px;
    }
    img {
      display: block;
      height: 25px;
      margin: 0 12px 0 8px;
    }
    span {
      vertical-align: middle;
    }
    button {
      margin: 4px 0;
    }
  }
}
