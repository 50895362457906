@import './variables';

html {
  height: 100%;
  margin: 0px;
}

body {
  margin: 0px;
  font-family: Walsheim-Regular, sans-serif;
  min-height: 100vh;
  height: 100%;
  color: $dark-text-color;
  font-size: 16px;
  overflow-y: scroll;

  #root {
    height: 100%;
  }
}

a {
  text-decoration: none;
  color: $primary-color;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: bold;
  margin-top: 0px;
}

pre {
  font-family: Walsheim-Regular, sans-serif;
  white-space: pre-line;
  margin: 0 auto;
  color: $light-text-color;
}
