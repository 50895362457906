@import '../../../../variables';

.ReportCard {
  position: relative;
  overflow: hidden;
  padding-bottom: 2px; // to make shadow visible
  transition: transform 0.15s ease-in-out;
  border-radius: 20px;
  border: 1px solid #ddd;

  &__visibility-restricted {
    background-color: $secondary-color;
  }

  &:hover {
    transform: scale(1.02, 1.02);
    cursor: pointer;
  }

  &__header {
    background-color: $segment-color-1;
  }

  .speeddial {
    button {
      width: 40px;
      height: 40px;
      box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.06), 0 2px 32px 0 rgba(0, 0, 0, 0.16);
    }
  }

  .share {
    position: absolute;
    right: 10px;
    top: 10px;
  }

  .two-line-truncate {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    line-clamp: 2;
  }
}
