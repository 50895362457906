@import '../../../../../variables';

.NewsCardLink {
  &__container {
    margin: 8px;
    border-radius: 50%;
    transition: all 0.15s ease-in-out;
    background-color: rgba(249, 249, 249, 0);
    color: #f9f9f9;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      transition: all 0.15s ease-in-out;
      background-color: $primary-color;
      color: white;
    }
  }

  .MuiSvgIcon-root {
    vertical-align: middle;
    font-size: 16px;
    color: $white-color;

    cursor: pointer;
    transform: rotate(135deg);
  }

  .GRTooltip {
    display: inline-flex;
  }

  .MuiIconButton-root {
    &:hover {
      background-color: transparent;
    }
  }
}
