@import '../../../variables';

.BookmarkCard {
  transition: box-shadow 0s;

  &:hover {
    box-shadow: 0 0 0 1px $primary-color;
    cursor: pointer;

    .BookmarkCard--Name {
      color: $primary-color;
    }
  }
}
