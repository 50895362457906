@import '../../../../variables.scss';

.MotivationArchetypeBadge {
    cursor: default;
    display: inline-block;    
    box-shadow: $card-box-shadow;
    border-radius: $default-border-radius;
    background-color: white;
    box-sizing: border-box;
    border: 1px solid rgba(0,0,0,0.06);
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 8px;    
    padding-right: 5px;
    max-height: calc(2*$default-border-radius);
    margin-right: 12px;
    margin-bottom: 8px;
    width: auto;
    font-weight: bold;
    transition: transform 0.15s ease-in-out;

    &:hover {        
        transform: scale(1.05, 1.05);
    }

    &__Content {
        padding-right: 3px;


    }

    &__Tooltip {
        &__Image {
            width: 100px;
            height: 100px;
        }
    }

    &__Image {
        max-height: 25px;
        margin-right: 5px;
        transform: translateY(2px);
    }

    &--Selected {
        border: 1px solid $primary-color;
        box-shadow: $card-box-shadow-large;
    }

    &--Selectable {
        .MotivationArchetypeBadge__Content {
          cursor: pointer;
        }
    }

    &--Active {
        &__Icon {
        text-align: center;
        color: $primary-color;
        height: 20px;
        width: 20px;
        border-radius: 20px;   
        margin-left: 5px;
    
        svg {
          font-size: 19px;
        }
    }
      }

    &:not(.MotivationArchetypeBadge--Active) {
        .MotivationArchetypeBadge__Content {
            padding-right: 10px;
          }
    }
}