@import 'src/variables';

.MarketTrendGraph {
  .MarketTrendActions {
    text-align: right;

    &__button {
      background-color: $secondary-color;
      border-radius: 32px;
      display: inline-block;
      line-height: 47px;
      margin-left: 10px;
      min-width: 36px;
      height: 36px;
      padding: 2px;
      text-align: center;
      transition: opacity 0.15s ease-out;
      &:hover {
        opacity: 0.75;
        cursor: pointer;
      }
      svg {
        color: white;
        height: 24px;
        width: 24px;
      }
    }

    &__disabled {
      background-color: $light-color;
      cursor: not-allowed;
      pointer-events: none;
    }
  }
  .TrendChanges {
    @media (min-width: $breakpoint-sm) {
      border-left: 1px solid $divider-default-color;
    }
    .ChangeValue {
      label {
        @media (min-width: $breakpoint-sm) {
          display: inline;
        }
        color: $light-color;
        display: inline-block;
        font-size: 0.9em;
        text-align: right;
        width: 50%;
      }
      .percentage {
        font-size: 24px;
        font-weight: 900;
        margin-left: 0.25em;
        vertical-align: middle;
        &.up {
          color: $success-color;
        }
        &.down {
          color: $warning-color;
        }
      }
    }
    h3 {
      margin: 36px 0 12px 0;
      text-align: center;
    }
    .TopPopularity {
      h3 {
        margin-top: 24px;
      }
      p {
        display: block;
        margin-top: -8px;
        text-align: center;
      }
    }
  }
}
