@import '../../variables';

.GRTooltip {
  &:hover {
    cursor: default;
  }
}

.Tooltip {
  padding: 1rem;
  border: 2px solid $primary-color;
  max-width: 15rem;

  &--NoMaxWidth {
    max-width: none;
  }

  &.success {
    border-color: $success-color;
  }

  &.warning {
    border-color: $warning-color;
  }
}

.MuiTooltip-tooltipPlacementTop {
  transform: translateY(-10px) !important;
}
