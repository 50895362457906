@import '../../variables';

.naviButton {
  height: 40px;
  box-shadow: $card-box-shadow;
  background-color: white;
  transition: background-color 150ms ease-in;
  &:hover {
    background-color: $primary-color;
  }
}

.contentContainer {
  text-align: center;
}
