@import '../../../../variables';

.FtueVideoPlaceholder {
  border-radius: 6px;
  border: 2px dashed $divider-default-color;
  color: $subtle-color;
  padding: 162px 16px;
  text-align: center;
  svg {
    color: $subtle-color;
    display: block;
    height: 48px;
    margin: 0 auto;
    width: auto;
  }
}
