@import '../../../../variables';

.FeatureLabelLink {
  &__Link {
    &:hover {
      color: $primary-color;
      cursor: pointer;
    }
  }

  &__Star {
    margin-top: -1px;
    margin-bottom: -1px;
    font-size: 16px;
    color: $primary-color;
  }
}
