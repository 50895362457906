.DemographicsTableBars {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 7px;
  background-color: #ddd;
  overflow: hidden;
  div {
    float: left;
    height: 100%;
    &.DemographicsTableBar__1 {
      background-color: #a4e3a4;
    }
    &.DemographicsTableBar__2 {
      background-color: #fae1b4;
    }
    &.DemographicsTableBar__3 {
      background-color: #f4b6bb;
    }
  }
}
