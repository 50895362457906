.FeatureGroupSelector {
  display: inline-block;
  vertical-align: top;
  &__FeatureOption {
    &:hover {
      cursor: pointer;
      opacity: 0.85;
    }
    > span {
      vertical-align: text-bottom;
    }
  }

  .MuiChip-root {
    color: white;
    vertical-align: middle;

    &:hover {
      cursor: pointer;
    }
  }
}
