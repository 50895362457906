@import '../../../../variables';

.GameSkillThinkingMeter {
  display: inline-block;
  position: relative;
  width: 50px;
  background-color: #eee;
  height: 15px;
  border-radius: 10px;
  overflow: hidden;
  border: 1px solid rgba(0, 0, 0, 0.1);
  font-size: 10px;
  margin-bottom: -2px;

  &__bar {
    position: absolute;
    top: 1px;
    bottom: 1px;
    left: 50%;
    background-color: $primary-color;
    opacity: 0.9;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;

    &--right {
      left: auto;
      right: 50%;
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px;
    }
  }

  &__center-divider {
    position: absolute;
    top: 0px;
    height: 100%;
    width: 1px;
    left: 50%;
    background-color: #aaa;
  }

  &__text {
    position: absolute;
    top: 1px;
    font-weight: bold;
    color: $dark-text-color;
  }

  &__left {
    left: 5px;
  }

  &__right {
    right: 5px;
  }
}
