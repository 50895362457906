@import 'src/variables';

.FtueVideoFullscreenModal {
  margin: 16px;
  padding: 16px 0;
  .MuiDialog-paper {
    border-radius: 6px;
    margin: 16px 0;
    padding: 16px;
    background-color: $app-background-color;
    position: relative;
  }
  .MuiGrid-container,
  .MuiGrid-item {
    height: 100%;
  }
  .sideBar {
    padding-left: 16px;
  }
  .chapterList {
    background-color: white;
  }
  .close {
    background-color: $primary-color;
    color: white;
    position: absolute;
    right: 8px;
    top: 8px;
  }
}
