@import '../../../../variables';
$base-spacing: 8px;
.MotivationRadarChart {
  &__container {
    canvas {
      width: 100% !important;
    }
  }
}

#motivation-chart-tooltip {
  background-color: rgba($color: #222, $alpha: 0.9);
  border-radius: $base-spacing;
  max-width: 300px;
  color: #fff;
  font-size: 13px;
  font-family: $primary-font-family;
  padding: $base-spacing;
  z-index: 1301;

  .motivation-chart-tooltip {
    &__body {
      display: grid;
      grid-template-columns: $base-spacing * 2 auto;
      grid-column-gap: 0px;
      grid-row-gap: 0px;
    }

    &__title {
      grid-area: 1 / 1 / 2 / 3;
      margin-bottom: $base-spacing;
      font-weight: bold;
      font-size: 15px;
    }

    &__description {
      margin: $base-spacing 0;
      grid-area: 2 / 1 / 3 / 3;
      font-style: italic;
    }

    &__value {
      display: contents;

      &__dot {
        grid-column-start: 1;
        grid-column-end: 2;
        display: flex;
        align-items: center;
        margin-top: $base-spacing;

        .dot {
          height: 16px;
          width: 16px;

          border-radius: 50%;
          display: inline-block;
        }
      }

      &__label {
        margin-top: $base-spacing;
        margin-left: $base-spacing;
        grid-column-start: 2;
        grid-column-end: 3;
        display: flex;
        align-items: center;
      }
    }
  }
}
