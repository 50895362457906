$indicator-height: 0.25rem;

.ApiFetchingIndicator {
  position: fixed;
  top: 0;
  z-index: 999;
  width: 100%;

  .MuiLinearProgress-root {
    border-radius: 0;
    height: $indicator-height;

    .MuiLinearProgress-bar {
      border-radius: 0;
      height: $indicator-height;
    }
  }
}
