@import '../../../../variables';
.BenchmarkGameSelector {
  transition: all 500ms ease-in-out;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  &__compareIcon {
    color: $light-color;
    border: 2px dashed $light-color;
    width: 62px;
    height: 62px;
    border-radius: $default-border-radius;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__benchmarkText {
    color: $light-color;
  }

  &:hover {
    &__benchmarkText {
      color: $dark-text-color;
    }
    &__compareIcon {
      border-color: $dark-text-color;
    }
    .MuiSvgIcon-root {
      color: $dark-text-color;
    }
  }
}
